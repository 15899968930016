<template>
  <DeepDialog
    v-model="dialog"
    fullscreen-on-mobile
    width="600"
    @keydown.esc="closeDialog"
  >
    <template #card-title-content-title>
      <div v-if="isSmsCode">
        {{ i18n.t('authorities.sms_code') }}
      </div>
      <img
        v-else
        :src="`/svg/authority-services/${authorityService}-logo.svg`"
        class="h-5"
        alt=""
      />
    </template>
    <template #content>
      <div class="font-semibold text-base pb-4">
        {{ i18n.t('titles.signature_cancelled') }}
      </div>
      <div class="text-md">
        {{ i18n.t('texts.signature_cancelled') }}
      </div>
    </template>
    <template #actions>
      <VBtnPrimary :text="i18n.t('buttons.close')" @click="closeDialog" />
    </template>
  </DeepDialog>
</template>

<script lang="ts" setup>
import { ref, onUnmounted, computed } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { AuthorityService } from '@/types/enums/AuthorityService';
import { SignatureMode } from '@/types/enums/SignatureMode';
import { useDocumentContext } from '@/composables/useDocumentContext';

const { currentDocument } = useDocumentContext();
const i18n = useI18n();
const emitter = useEmitter();
const dialog = ref(false);
const authorityService = ref();

emitter.$on('show-signature-cancelled-dialog', showDialog);

onUnmounted(() => {
  emitter.$off('show-signature-cancelled-dialog', showDialog);
});

const isSmsCode = computed(() => {
  return (
    currentDocument.value?.signatureMode === SignatureMode.ADVANCED &&
    authorityService.value === AuthorityService.MOBILE_ID
  );
});

function showDialog(data: { authorityService: AuthorityService }) {
  dialog.value = true;
  authorityService.value = data.authorityService;
}

function closeDialog() {
  dialog.value = false;
  authorityService.value = undefined;
}
</script>

<style scoped lang="scss">
.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 24px !important;
  text-align: center;
  white-space: normal;
}
</style>
