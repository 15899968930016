<template>
  <div class="pa-0 w-full">
    <div class="d-flex flex-column align-center handwritten-container">
      <div
        ref="dropzone"
        class="handwritten-signature pad-signature"
        :class="[
          { dragover: hover },
          { 'required-signature': hasRequiredAutograph },
          { 'wrong-format': errorState }
        ]"
        @dragenter.prevent="dragenter"
        @dragleave.prevent="dragleave"
        @dragover.prevent="dragover"
        @drop.prevent="drop"
      >
        <input
          ref="fileInput"
          :accept="acceptedExtensions"
          class="fileInput"
          type="file"
          @change="onFileInputChange"
        />
        <VueSignaturePad
          v-if="!imgSrc && !signatureUrl"
          id="signature-pad"
          ref="signaturePad"
          class="pad"
          :options="{ onBegin, onEnd, penColor }"
          width="100%"
          :data-test-id="`signature-pad-authorized-${hasDefaultAutograph}`"
        />
        <img
          v-else-if="signatureUrl"
          id="uploadPreview"
          :alt="uploadedFileName"
          :src="signatureUrl"
          data-test-id="signature-pad-filled"
          class="uploadPreview"
        />
        <img
          v-else-if="imgSrc"
          id="uploadPreview"
          :src="imgSrc"
          :alt="uploadedFileName"
          class="uploadPreview"
        />
        <div>
          <v-divider class="mx-5" />
          <div class="sign-instructions">
            <template v-if="!uploadedFile">
              <div
                v-if="fileInput"
                class="signature-hint"
                @click="
                  () => {
                    if (fileInput) {
                      fileInput.value = '';
                      fileInput.click();
                    }
                  }
                "
              >
                <I18nT keypath="texts.draw_your_signature">
                  <template #highlight>
                    <button class="text-primary">
                      {{ i18n.t('actions.upload') }}
                    </button>
                  </template>
                </I18nT>
              </div>
            </template>
            <template v-else>
              {{ uploadedFile.name }}
            </template>
          </div>
        </div>
      </div>
      <span v-if="errorState" class="text-xs text-error">
        {{ i18n.t('errors.unsupported_file_format') }}
      </span>
      <div
        class="d-flex flex-row justify-space-between align-center h-4 w-full"
      >
        <button
          class="flex items-center text-xs hover:text-primary cursor-pointer signature-reset"
          :data-test-id="`signature-clear-authorized-${hasDefaultAutograph}`"
          @click="clear"
        >
          <v-icon v-if="canClear || signatureUrl" size="13" class="mr-1"
            >fa fa-eraser</v-icon
          >
          {{ canClear || signatureUrl ? i18n.t('buttons.clear') : '' }}
        </button>
        <button
          v-if="AuthService.isAuthenticated"
          class="text-caption ml-auto text-primary"
          @click="openSignatureEdition"
        >
          {{ i18n.t('texts.customize_signature') }}
        </button>
      </div>
      <div
        v-if="hasRequiredAutograph"
        class="required-signature-card"
        data-test-id="required-signature-message"
      >
        <v-icon size="28" class="mr-1 text-primary">
          fa-light fa-circle-info
        </v-icon>
        <span class="ml-4 font-light">
          {{ i18n.t('info.required_signature') }}
        </span>
      </div>
    </div>
    <MustLoginDialog v-model="loginDialog" :signer-email="signerEmail" />
    <ImageCropDialog
      ref="imageCropDialog"
      :img-src="imgSrc"
      @submit="upload"
      @close="imgSrc = null"
    />
  </div>
</template>

<script setup lang="ts">
import { constants } from '@/constants/constants';
import AuthService from '@/services/auth-service';
import { useMessageStore } from '@/stores/message/message';
import { useSignStore } from '@/stores/sign/sign';
import { ref, computed, onMounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import I18nT from '@/components/I18nT.vue';
import { getAllFileEntries } from '@/composables/useUtils';
import { useDocumentContext } from '@/composables/useDocumentContext';

const emitter = useEmitter();
const i18n = useI18n();
const signStore = useSignStore();
const messageStore = useMessageStore();
const props = defineProps({
  penColor: { type: String, required: false, default: 'black' },
  signerEmail: { type: String, default: '' },
  hasDefaultAutograph: { type: Boolean, default: false },
  initialSignatureUrl: {
    type: String,
    default: undefined
  }
});

const canClear = ref(false);
const handwrittenSignatureData = ref<string | ArrayBuffer | null>(null);
const hover = ref(false);
const uploadedFile = ref<File>();
const acceptedExtensions = ref('image/png, image/jpeg');
const signaturePad = ref();
const loginDialog = ref(false);
const fileInput = ref<HTMLInputElement>();
const { currentDocument } = useDocumentContext();
const errorState = ref(false);
const imageCropDialog = ref();
const imgSrc = ref();

const uploadedFileName = computed(() => {
  return uploadedFile.value?.name || '';
});

const signatureUrl = computed(() => {
  return props.initialSignatureUrl;
});

const hasRequiredAutograph = computed(() => {
  return (
    currentDocument.value?.autographRequired &&
    !(
      signatureUrl.value ||
      handwrittenSignatureData.value ||
      uploadedFile.value ||
      props.initialSignatureUrl
    )
  );
});

onMounted(() => {
  if (
    signStore.signInfo &&
    !props.initialSignatureUrl &&
    signStore.autographData &&
    AuthService.isAuthenticated
  ) {
    signStore.signInfo.defaultAutographUrl = signStore.autographData;
  }
});

function openSignatureEdition() {
  emitter.$emit('open-signature-edition-dialog');
}

function onBegin() {
  signaturePad.value.resizeCanvas();
  errorState.value = false;
  canClear.value = true;
}

function onEnd() {
  const { data } = signaturePad.value.saveSignature();
  handwrittenSignatureData.value = data;
  const ownSignaturePlaceholder = document.getElementById(
    `signature-preview-${signStore.userSigneeId}`
  );
  if (ownSignaturePlaceholder) {
    emitter.$emit('update-autograph-data', handwrittenSignatureData.value);
  }
}

function clear() {
  if (uploadedFile.value) {
    uploadedFile.value = undefined;
    if (fileInput.value) {
      fileInput.value.value = '';
    }
  } else if (!props.initialSignatureUrl && signaturePad.value) {
    signaturePad.value.clearSignature();
  }
  handwrittenSignatureData.value = null;
  canClear.value = false;
  emitter.$emit('update-autograph-data', handwrittenSignatureData.value);
  if (signStore.signInfo) {
    signStore.signInfo.defaultAutographUrl = undefined;
  }
}

function dragenter() {
  hover.value = true;
}

function dragleave() {
  hover.value = false;
}

function dragover() {
  hover.value = true;
}

async function drop(event: DragEvent) {
  hover.value = false;
  const entries = await getAllFileEntries(event);
  if (entries.files.length > 1) {
    messageStore.showMessage({
      color: 'error',
      key: 'errors.multiple_files_forbidden',
      icon: 'fal fa-triangle-exclamation'
    });
    return;
  }
  if (entries.files.length === 1) {
    if (
      entries.files[0].file &&
      !constants.SUPPORTED_AUTOGRAPH_TYPES.includes(entries.files[0].file.type)
    ) {
      errorState.value = true;
      return;
    }

    if (entries.files[0].file) {
      errorState.value = false;
      onFileChange(entries.files[0].file);
    }
    canClear.value = true;
    fileInput.value = '';
  }
}

function isValidUrl(urlString: string): boolean {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
}

function upload(image: string | null) {
  if (typeof image === 'string' && isValidUrl(image)) {
    setPreview(image);
    errorState.value = false;
    canClear.value = true;
    imgSrc.value = undefined;
  }
}

function onFileChange(file: File) {
  const reader = new FileReader();
  reader.onload = (e) => {
    imgSrc.value = e.target.result;
    imageCropDialog.value.dialog = true;
  };
  reader.readAsDataURL(file);
}

function onFileInputChange(event) {
  if (!event.target.files || !event.target.files[0]) return;
  const input = event.target as HTMLInputElement;
  const files = input.files as FileList;

  if (files && !constants.SUPPORTED_AUTOGRAPH_TYPES.includes(files[0].type)) {
    errorState.value = true;
    return;
  }
  onFileChange(event.target.files[0]);
}

function setPreview(imageSource: string) {
  const uploadPreview = document.getElementById(
    'uploadPreview'
  ) as HTMLImageElement;
  if (imageSource) {
    uploadPreview.src = imageSource;
    handwrittenSignatureData.value = imageSource;
    if (signStore.signInfo) {
      signStore.signInfo.defaultAutographUrl = imageSource;
    }
  }
  emitter.$emit('update-autograph-data', handwrittenSignatureData.value);
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

@media (min-width: 1264px) {
  .pad-signature {
    max-height: 186px;
  }
}
.pad-signature {
  width: 100%;
  height: 100%;
  border: 1px solid $grey;
  margin: 0 0 10px;
  border-radius: 4px;
}

.sign-instructions {
  font-size: 0.75rem !important;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  color: $grey-darken-1;
  text-align: center;
  padding: 4px 20px;
}

.handwritten-signature {
  /* TRANSITION */
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
}

.pad {
  aspect-ratio: 196/48;

  &:hover {
    cursor: url('data:image/svg+xml;utf8,<svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgba(22,32,81,0.9)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 19l7-7 3 3-7 7-3-3z"/><path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"/><path d="M2 2l7.586 7.586"/><circle cx="11" cy="11" r="2"/></svg>'),
      crosshair;
  }
}

.dragover {
  background-color: rgba(93, 74, 198, 0.05);
}

input.fileInput {
  display: none;
}

.uploadPreview {
  max-height: 140px !important;
  width: 100%;
  object-fit: contain !important;
  aspect-ratio: 196/48;
  object-position: left;
}

.handwritten-container {
  padding-top: 16px;
}

.signature-hint {
  &:hover {
    cursor: pointer;
  }
}

.signature-reset i {
  color: #757575;
}

.signature-reset:hover i {
  color: rgb(0, 82, 255);
}

.required-signature {
  @apply border-2 border-primary;
}

.wrong-format {
  @apply border border-error;
}

.required-signature-card {
  @apply h-auto w-full border border-primary bg-[#DBEAFE] px-4 py-2 my-2 mt-2 flex items-center rounded-md;
}
</style>
