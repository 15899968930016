<template>
  <DeepDialog
    v-model="showDialog"
    width="600"
    :title="i18n.t('titles.batch_start')"
    :persistent="sendingInvitations"
    fullscreen-on-mobile
    @keydown.esc="closeDialog"
    @close="closeDialog"
  >
    <template #content>
      <div
        class="border-gray-400 border border-dashed px-4 py-2 max-h-[170px] overflow-y-auto"
      >
        <StartedDocument
          v-for="document in documents"
          :key="document.documentId"
          :document="document"
          @on-error="handleError"
          @on-success="handleSuccess"
        />
      </div>
      <div
        v-if="
          successfullyStartedDocuments >= 1 ||
          unsuccessfullyStartedDocuments > 0
        "
        class="mt-6 grid gap-2 w-full"
      >
        <DeepSignAlert
          v-if="documents && successfullyStartedDocuments >= 1"
          class="w-full"
          success
        >
          {{
            i18n.t('aggregated.started', {
              count: successfullyStartedDocuments,
              total: documents.length
            })
          }}
        </DeepSignAlert>

        <DeepSignAlert
          v-if="documents && unsuccessfullyStartedDocuments >= 1"
          warning
          class="w-full"
        >
          <div
            v-for="[errorKey, errorData] in Object.entries(aggregatedErrors)"
            :key="errorKey"
            class="text-left"
          >
            {{
              i18n.t(`errors.aggregated.${errorKey}`, {
                count: errorData.count,
                total: documents.length
              })
            }}
          </div>
        </DeepSignAlert>
      </div>
    </template>
    <template #actions>
      <VBtnPrimary
        :disabled="sendingInvitations"
        :text="i18n.t('buttons.close')"
        @click="closeDialog"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { ref, onUnmounted, watchEffect } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import type { OverviewDocument } from '@/stores/overview/overview';
import { useMessageStore } from '@/stores/message/message';

const i18n = useI18n();
const emitter = useEmitter();
const showDialog = ref(false);
const documents = ref<OverviewDocument[]>();
const successfullyStartedDocuments = ref(0);
const unsuccessfullyStartedDocuments = ref(0);
const aggregatedErrors = ref<Record<string, { count: number }>>({});
const messageStore = useMessageStore();
const sendingInvitations = ref(true);

const emit = defineEmits(['done']);
emitter.$on('open-batch-start-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-batch-start-dialog', openDialog);
});

function openDialog(docs: OverviewDocument[]) {
  if (docs) documents.value = docs;
  showDialog.value = true;
  // handleBatchStart();
}

function closeDialog() {
  if (
    successfullyStartedDocuments.value ||
    unsuccessfullyStartedDocuments.value
  ) {
    emit('done');
  }
  showDialog.value = false;
  unsuccessfullyStartedDocuments.value = 0;
  successfullyStartedDocuments.value = 0;
  aggregatedErrors.value = {};
  documents.value = [];
  sendingInvitations.value = true;
}

function handleSuccess() {
  successfullyStartedDocuments.value++;
}

function handleError(error: string) {
  unsuccessfullyStartedDocuments.value++;
  if (!aggregatedErrors.value[error]) {
    aggregatedErrors.value[error] = { count: 1 };
  } else {
    aggregatedErrors.value[error].count++;
  }
}

watchEffect(() => {
  if (
    documents.value &&
    successfullyStartedDocuments.value > 0 &&
    successfullyStartedDocuments.value === documents.value.length
  ) {
    messageStore.showMessage({
      text: i18n.t('snackbars.successfully_sent_invitations', {
        number: successfullyStartedDocuments.value
      }),
      color: 'success',
      icon: 'fa fa-circle-check'
    });
    closeDialog();
  }
});

watchEffect(() => {
  if (
    documents.value &&
    documents.value.length ===
      successfullyStartedDocuments.value + unsuccessfullyStartedDocuments.value
  ) {
    sendingInvitations.value = false;
  }
});
</script>

<style lang="scss" scoped>
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}

.email-input :deep(.v-input__details) {
  padding: 0%;
}
</style>
