<template>
  <v-card
    class="authority-service-item-card border border-gray-300"
    :class="{ 'active-authority-service': active }"
    max-width="292"
    variant="outlined"
    rounded="lg"
    @click="setAuthorityService"
    @keydown.space="setAuthorityService"
    @keydown.enter="setAuthorityService"
  >
    <div class="d-flex flex-row align-center">
      <div class="d-flex flex-column mr-4">
        <v-icon v-if="active" color="primary" size="small">
          far fa-circle-dot
        </v-icon>
        <v-icon v-else size="small"> far fa-circle </v-icon>
      </div>
      <div class="d-flex flex-column">
        <!-- DISABLE EsLint here because of https://github.com/vuetifyjs/eslint-plugin-vuetify/issues/83 -->
        <!-- eslint-disable -->
        <v-img
          v-if="showLogo"
          class="ma-0 mb-2"
          cover
          height="100%"
          max-width="62"
          position="left"
          :src="`/svg/authority-services/${authorityService}-logo.svg`"
          style="text-align: left"
        />
        <!-- eslint-enable -->
        <div v-else class="sms-code">
          {{ i18n.t('authorities.sms_code') }}
        </div>
        <div class="deep-caption authority-service-description">
          {{
            i18n.t(
              `authorities.${
                showLogo ? authorityService : 'sms_code'
              }_description`
            )
          }}
        </div>
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';

// Global
const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  authorityService: {
    type: String,
    required: true
  },
  signatureMode: {
    type: String,
    required: true
  }
});

const emitter = useEmitter();
const i18n = useI18n();

// Show Logo
const showLogo = computed(() => {
  if (props.signatureMode === SignatureMode.ADVANCED) {
    return props.authorityService === AuthorityService.DEEP_ID;
  } else {
    return (
      props.authorityService !== null && props.authorityService !== undefined
    );
  }
});

// Set Authority Service
function setAuthorityService() {
  emitter.$emit('set-authority-service', props.authorityService);
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.authority-service-item-card {
  width: 100%;
  padding: 16px;
}

.authority-service-description {
  font-size: 12px !important;
}

.active-authority-service {
  border-color: $primary-color;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;
}

.sms-code {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #090909;
}
</style>
