<template>
  <div class="flex justify-between items-center w-full gap-2 h-[40px]">
    <div class="truncate">
      {{ startedDocument.documentName }}
    </div>
    <div v-if="status === 'pending'" class="d-flex flex-column align-end">
      <v-icon size="14"> fal fa-hourglass-start </v-icon>
    </div>
    <v-menu
      v-if="status === 'started'"
      location="left top"
      max-width="300"
      open-on-hover
      open-on-click
    >
      <template #activator="{ props: ActivatorProps }">
        <v-icon v-bind="ActivatorProps" color="success" size="14">
          fal fa-circle-check
        </v-icon>
      </template>
      <v-card>
        <v-card-text class="text-caption">
          {{ i18n.t('texts.invitation_sent') }}
        </v-card-text>
      </v-card>
    </v-menu>
    <!-- Uploading state -->
    <v-progress-circular
      v-else-if="status === 'progress'"
      color="primary"
      indeterminate
      class="mt-1.5"
      size="16"
      width="1"
    />
    <!-- Error state -->
    <v-menu
      v-if="status === 'error'"
      location="left top"
      max-width="300"
      open-on-hover
      open-on-click
    >
      <template #activator="{ props: ActivatorProps }">
        <v-icon v-bind="ActivatorProps" color="error" size="14">
          fal fa-triangle-exclamation
        </v-icon>
      </template>
      <v-card>
        <v-card-text class="text-caption">
          {{ i18n.t(`errors.api.${apiError}`) }}
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, watchEffect } from 'vue';
import type { OverviewDocument } from '@/stores/overview/overview';
import { useI18n } from 'vue-i18n';
import { storeStartedDocument } from '@/api/deepsign/start';

const i18n = useI18n();
const props = defineProps<{
  document: OverviewDocument;
}>();
const emit = defineEmits(['onSuccess', 'onError']);

const startedDocument = ref(props.document);
const status = ref<'pending' | 'progress' | 'started' | 'error'>('pending');
const apiError = ref();

async function handleBatchStart() {
  status.value = 'progress';
  try {
    const response = await storeStartedDocument(props.document.documentId);
    if (response.status === 204) status.value = 'started';
    emit('onSuccess');
  } catch (error) {
    status.value = 'error';
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.messageId
    ) {
      apiError.value = error.response.data.messageId;
      emit('onError', apiError.value);
    }
  }
}

watchEffect(() => {
  if (status.value === 'pending') {
    handleBatchStart();
  }
});
</script>
