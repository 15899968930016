<template>
  <DeepDialog
    v-model="showDialog"
    fullscreen-on-mobile
    width="600"
    :title="i18n.t('titles.comment')"
  >
    <template #content>
      <div class="text-content">
        <pre class="text-subtitle-1 text-pre-wrap">{{ comment }}</pre>
      </div>
    </template>
    <template #actions>
      <VBtnPrimary :text="i18n.t('buttons.close')" @click="closeDialog" />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { DeepDialog } from '@deepcloud/deep-ui-lib';
import { useI18n } from 'vue-i18n';
import { onUnmounted, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';

defineProps({
  signee: {
    type: Object,
    required: true
  },
  comment: {
    type: String,
    required: true
  }
});

const emitter = useEmitter();
const showDialog = ref(false);
const i18n = useI18n();

emitter.$on('open-signee-comment-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-signee-comment-dialog', openDialog);
});

function openDialog() {
  showDialog.value = true;
}

function closeDialog() {
  showDialog.value = false;
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.dialog-container {
  padding: 24px !important;
}

.card-title {
  word-break: break-word;
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.text-content {
  border-radius: 12px;
  min-width: 100%;
  text-align: start;
  background: $grey-lighten-4;
  padding: 24px;
  color: black;
}

.button-container {
  margin: 0 24px;
  padding: 0 40px;
}

.signee-email-container {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: black;

  div {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.004em;
    color: $grey;
  }
}
</style>
