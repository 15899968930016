<template>
  <!-- ORDER SWITCH -->
  <CustomSwitchWrapper
    class="mb-2"
    :initial-value="createStore.ordered"
    :label="i18n.t('titles.define_order_of_signing')"
    label-class="drawer-subhead"
    :disabled="createStore.isLoading"
    :switch-id="i18n.t('titles.define_order_of_signing')"
    data-test-id="signing-order-switch"
    @value-changed="
      (value: boolean) => createStore.onOrderSwitchToggle(value, false, null)
    "
    @click="onHandleOrderChanged"
  />
  <!-- ORDER SWITCH -->
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useCreateStore } from '@/stores/create.ts';
import { computed, onBeforeMount, onBeforeUnmount, watch } from 'vue';

const createStore = useCreateStore();
const hasOrderBeenSetManually = computed(() => createStore.hasApprovers);
const i18n = useI18n();

function onHandleOrderChanged() {
  if (!createStore.ordered) {
    createStore.hasOrderBeenSetManually = true;
  }
}

onBeforeMount(() => {
  createStore.ordered =
    createStore.document!.signeesOrdered.filter((groups) => groups.length > 0)
      .length >= 2 || false;
});

onBeforeUnmount(() => {
  createStore.hasOrderBeenSetManually = false;
});

watch(hasOrderBeenSetManually, () => {
  // switch order off if user did not switch order manually ON AND if there are no approvers
  if (!createStore.hasApprovers) {
    createStore.onOrderSwitchToggle(false, false, null);
  }
});
</script>
