<template>
  <DeepDialog
    v-model="showDialog"
    fullscreen-on-mobile
    width="600"
    :title="i18n.t('titles.withdraw_document')"
    @keydown.esc="showDialog = false"
  >
    <template #content>
      <DeepSignAlert
        v-if="unavailableDocuments"
        warning
        class="text-red-500 mb-6 w-full"
      >
        <div class="flex flex-row align-center">
          <VIcon :size="24" class="mr-4 text-yellow-700">
            fa-regular fa-circle-exclamation
          </VIcon>
          <span class="text-yellow-700 text-[14.4px]">{{
            i18n.t('texts.cant_withdraw_policy', {
              availableDocs: _documents.length - unavailableDocuments,
              total: _documents.length
            })
          }}</span>
        </div>
      </DeepSignAlert>
      <div class="!p-0 text-black mb-4">
        {{ i18n.t('texts.withdraw_document') }}
      </div>
      <div class="text-sm text-zinc-600 mb-1">
        {{ i18n.t('titles.withdraw_message') }}
        ({{ i18n.t('captions.optional') }})
      </div>
      <v-textarea
        v-model="message"
        :label="i18n.t('labels.message')"
        no-resize
        variant="outlined"
        class="withdraw-message"
        :placeholder="i18n.t('placeholders.withdraw')"
        data-test-id="withdraw-message-dialog"
      />
    </template>
    <template #actions>
      <VBtnSecondary
        variant="outlined"
        :text="i18n.t('buttons.cancel')"
        data-test-id="close-dialog-withdraw"
        @click="() => (showDialog = false)"
      />
      <VBtnPrimary
        :disabled="unavailableDocuments === _documents.length"
        :loading="withdrawDocumentPending"
        :text="i18n.t('buttons.withdraw')"
        data-test-id="withdraw-document"
        @click="withdraw"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { storeWithdrawnDocument } from '@/api/deepsign/withdraw';
import { SendMail } from '@/types/enums/SendMail';
import { useMessageStore } from '@/stores/message/message';
import { ref, onUnmounted, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useEmitter } from '@/composables/useEmitter';
import { useSignStore } from '@/stores/sign/sign';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const emitter = useEmitter();

emitter.$on('open-document-withdraw-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-document-withdraw-dialog', openDialog);
});

const showDialog = ref(false);
const message = ref();
const withdrawDocumentPending = ref(false);
const router = useRouter();
const route = useRoute();
const messageStore = useMessageStore();
const i18n = useI18n();
const documentID = ref();
const signStore = useSignStore();
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _documents = ref<any[]>([]);
const emit = defineEmits(['done']);

const unavailableDocuments = computed(() => {
  return _documents.value.filter((doc) => doc.policy && !doc.policy.canWithdraw)
    .length;
});

function openDialog(documents) {
  if (documents) _documents.value = documents;

  //If on DocumentView
  if (
    signStore.signInfo &&
    signStore.signInfo.document &&
    signStore.signInfo.document.documentId
  ) {
    _documents.value = [
      { ...signStore.signInfo.document, policy: signStore.signInfo.policy }
    ];
  }
  showDialog.value = true;
}

async function withdraw() {
  withdrawDocumentPending.value = true;
  let hasErrors = false;

  const documentsToWithdraw = _documents.value.filter((doc) => {
    return doc.policy.canWithdraw;
  });

  const withdrawPromises = documentsToWithdraw.map((document) => {
    const payload = {
      message: message.value,
      sendEmail: SendMail.OTHERS
    };

    return storeWithdrawnDocument(document.documentId, payload);
  });

  try {
    const results = await Promise.allSettled(withdrawPromises);

    hasErrors = results.some((result) => result.status === 'rejected');

    if (!hasErrors) {
      if (route.name === 'dashboard') {
        emit('done');
      } else {
        router.push(`/document/${_documents.value[0].documentId}/details`);
      }
      if (_documents.value.length > 1) {
        messageStore.showMessage({
          text: i18n.t('snackbars.document_withdrawn_multiple', {
            number: _documents.value.length
          }),
          color: 'success',
          icon: 'fa fa-circle-check'
        });
      } else {
        messageStore.showMessage({
          key: 'snackbars.document_withdrawn',
          color: 'success',
          icon: 'fa fa-circle-check'
        });
      }
    } else {
      messageStore.showMessage({
        key: 'errors.api.withdraw_document',
        color: 'error',
        icon: 'fa fa-circle-exclamation'
      });
    }
  } catch (error) {
    hasErrors = true;
    messageStore.showMessage({
      key: 'errors.api.withdraw_document',
      color: 'error',
      icon: 'fa fa-circle-exclamation'
    });
  } finally {
    withdrawDocumentPending.value = false;
    showDialog.value = false;
    message.value = undefined;
    documentID.value = undefined;
  }
}
</script>

<style scoped lang="scss">
.withdraw-message :deep(.v-input__details) {
  visibility: none;
}
</style>
