<template>
  <div id="document-info" class="md:container mobile-container">
    <!-- REQUEST DETAILS -->
    <div data-test-id="requestor-details">
      <!-- TITLE -->
      <div class="sign-requirement-title pb-1">
        {{ i18n.t('titles.requestor') }}
      </div>
      <!-- /TITLE -->

      <!-- REQUESTOR ORGANIZATION AND NAME -->
      <div
        class="flex items-center flex-wrap text"
        data-test-id="requestor-org-name"
      >
        <div
          class="flex items-center"
          :data-test-id="`organization-${initiatorCompanyDisplayName}`"
        >
          {{ initiatorCompanyDisplayName }}
          <v-tooltip
            v-model="showVerificationTooltip"
            :close-delay="1000"
            content-class="tooltip deep-light tooltip-top"
            location="top"
            :aria-label="verificationText"
            open-on-click
          >
            <template #activator="{ props: ActivatorProps }">
              <v-icon
                v-bind="ActivatorProps"
                class="mx-1"
                :color="verificationIconColor"
                :size="14"
                aria-label="Verification icon"
              >
                {{ verificationIcon }}
              </v-icon>
            </template>
            <div class="verification-badge-details">
              {{ verificationText }}
              <br />
              <a
                class="tooltip-link text-primary pointer-events-auto"
                :href="String(i18n.t('texts.verification_badge_details.link'))"
                target="_blank"
                rel="noopener"
                >{{ i18n.t('texts.learn_more') }}</a
              >
            </div>
          </v-tooltip>
        </div>
        <span class="flex-shrink-0" data-test-id="requestor-display-name">
          ({{ requestorDisplayName }})
        </span>
      </div>
      <!-- /REQUESTOR ORGANIZATION AND NAME -->

      <!-- REQUESTOR EMAIL -->
      <div
        class="sub-text mb-2"
        :data-test-id="`requestor-email-${requestorEmail}`"
      >
        {{ requestorEmail }}
      </div>
      <!-- /REQUESTOR EMAIL -->

      <!-- DATE TIME -->
      <span
        v-if="documentStartTime"
        v-tooltip="{
          text: timestampToDate(documentStartTime),
          location: 'right',
          offset: 4,
          openDelay: 300
        }"
        :aria-label="timestampToDate(documentStartTime)"
        class="d-flex flex-row align-center sub-text w-fit"
        data-test-id="date-time"
      >
        {{ formatDateToLocale(documentStartTime, i18n.locale.value, true) }}
      </span>
      <!-- DATE TIME -->

      <div
        v-if="
          signStore.signInfo?.document.jurisdiction &&
          signStore.signInfo?.document.signatureMode &&
          !isSignatureTypeSeal
        "
      >
        <v-divider class="my-5" />
        <DocumentSignatureLevel
          :jurisdiction="signStore.signInfo?.document.jurisdiction"
          :signature-mode="signStore.signInfo?.document.signatureMode"
        />
        <!-- <v-divider class="my-5" /> -->
      </div>
    </div>
    <!-- /REQUEST DETAILS -->

    <!-- COMMENT FROM REQUESTOR -->
    <template v-if="documentComment">
      <v-divider class="my-5" />
      <div>
        <div class="sign-requirement-title pb-1">
          {{ i18n.t('titles.message') }}
        </div>
        <div
          v-if="
            document &&
            document.initiatorCompanyVerificationType ===
              VerificationState.STRONG
          "
          id="message-preview"
          data-test-id="message-preview"
          class="text-subtitle-1 font-medium"
          v-html="fixedHrefsComment"
        ></div>
        <div v-else>
          <pre class="text-subtitle-1 font-medium text-pre-wrap">{{
            comment
          }}</pre>
        </div>
      </div>
    </template>
    <!-- /COMMENT FROM REQUESTOR -->

    <!-- SIGNEE/APPROVER ADD COMMENT -->
    <div
      v-if="showMessageToRequestorButton && !toggleMessageField"
      class="add-message-button-container"
    >
      <v-divider class="my-5" />

      <button class="add-message-button" @click="showMessageField">
        {{ i18n.t('buttons.add_message') }}
      </button>
    </div>
    <div v-if="toggleMessageField && showMessageToRequestorButton">
      <v-textarea
        ref="addMessageTextArea"
        v-model="message"
        class="mt-4"
        clearable
        hide-details
        :label="i18n.t('labels.add_message')"
        no-resize
        variant="outlined"
        @blur="updateDocumentMessage"
        @focus="setInitialMessage"
      />
    </div>
    <!-- /SIGNEE ADD COMMENT -->

    <v-divider v-if="!isSignatureTypeSeal" class="mt-5 mb-2" />
    <v-expansion-panels
      v-if="!isSignatureTypeSeal"
      v-model="panel"
      multiple
      flat
      variant="accordion"
      data-test-id="invited-signatories-panel"
    >
      <!-- INVITED SIGNEES -->
      <InvitedSignatories />

      <!-- INVITED SIGNEES -->

      <!-- OBSERVERS -->
      <v-expansion-panel v-show="hasObservers" @click="scroll">
        <v-expansion-panel-title
          static
          class="font-medium expansion-panel-title"
          data-test-id="observers-expansion-panel"
        >
          {{ i18n.t('titles.observers') }}
          <template #actions="{ expanded }">
            <VIcon :size="12" :class="{ 'rotate-180': expanded }">
              fa-regular fa-chevron-down
            </VIcon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text
          v-if="document && document.observers"
          class="observer-panel-content"
        >
          <template v-for="obs in document.observers" :key="obs.observerId">
            <ObserverItem :observer="obs" />
          </template>
        </v-expansion-panel-text>
      </v-expansion-panel>
      <!-- /OBSERVERS -->

      <!-- ATTACHMENTS -->
      <AttachmentsPreviewCard
        v-if="signStore.signInfo && signStore.signInfo.attachments?.length"
        :attachments="signStore.signInfo.attachments"
      />
    </v-expansion-panels>

    <!-- /ATTACHMENTS -->

    <!-- ACTIONS -->
    <template v-if="showActions">
      <v-divider class="my-4 mb-5" />
      <div class="d-flex flex-row justify-center">
        <div class="button-container">
          <DeepButton
            v-if="
              SignatureService.isSignedByUser(me, signees) &&
              route.name !== 'sign-id' &&
              !isDocumentAuthRoute
            "
            :action="goBackToOverview"
            class="mt-3"
            :text="i18n.t('buttons.overview')"
          />
          <DeepButton
            v-if="document && document.documentUrl"
            :action="download"
            variant="outlined"
            class="mt-2"
            :text="i18n.t('buttons.download')"
            data-test-id="download-document"
          />
        </div>
      </div>
    </template>
    <!-- /ACTIONS -->
  </div>
</template>

<script setup lang="ts">
import DOMPurify from 'dompurify';
import { downloadDocumentPdf } from '@/services/documentService';
import { addHrefSchemes } from '@/services/utilsService';
import AuthService from '@/services/auth-service';
import { VerificationState } from '@/types/enums/VerificationState';
import { useSignStore } from '@/stores/sign/sign';
import SignatureService from '@/services/signatureService';
import { ref, computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { formatDateToLocale } from '@/composables/useUtils';
import type { Document } from '@/types/Document';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useSignContext } from '@/composables/useSignContext';
import { timestampToDate } from '@deepcloud/deep-ui-lib';
import { nextTick } from 'process';

const props = defineProps({
  document: {
    type: Object as PropType<Document>,
    default: undefined
  },
  signees: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update-signature-message']);

const { isDocumentAuthRoute } = useDocumentContext();

const signStore = useSignStore();
const panel = ref([0, 1, 2]);
const toggleMessageField = ref(false);
const message = ref('');
const initialMessage = ref('');
const showVerificationTooltip = ref(false);
const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const { isSignatureTypeSeal, signKey } = useSignContext();
const addMessageTextArea = ref();
const comment = computed(() => {
  return props.document?.comment || '';
});

const requestorEmail = computed(() => {
  if (props.document) {
    return props.document.initiatorDisplayEmail;
  }
  return undefined;
});

const requestorDisplayName = computed(() => {
  if (props.document) {
    return props.document.initiatorDisplayName;
  }
  return undefined;
});

const isRequestor = computed(() => {
  if (props.document) {
    return SignatureService.compareString(
      props.document.initiatorDisplayEmail,
      AuthService.user?.email
    );
  }
  return false;
});

const initiatorCompanyDisplayName = computed(() => {
  return props.document?.initiatorCompanyDisplayName || '';
});

const verificationIcon = computed(() => {
  return props.document?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? 'fas fa-shield-check'
    : 'fas fa-shield-xmark';
});

const verificationIconColor = computed(() => {
  return props.document?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? 'primary'
    : '#F99C2C';
});

const verificationText = computed(() => {
  return props.document?.initiatorCompanyVerificationType ===
    VerificationState.STRONG
    ? i18n.t('texts.verification_badge_details.verified')
    : i18n.t('texts.verification_badge_details.unverified');
});

const me = computed(() => {
  return signStore.signInfo?.email;
});

const showMessageToRequestorButton = computed(() => {
  if (me.value && props.document) {
    return (
      (!isRequestor.value &&
        SignatureService.canApprove(me.value, props.document)) ||
      SignatureService.canSign(me.value, props.document)
    );
  } else {
    return false;
  }
});

const showActions = computed(() => {
  return (
    props.document?.documentUrl ||
    (me.value &&
      SignatureService.isSignedByUser(me.value, props.signees) &&
      route.name !== 'sign-id')
  );
});

const sanitizedComment = computed(() => {
  if (props.document?.comment) {
    if (documentComment.value) {
      return DOMPurify.sanitize(documentComment.value, {
        ALLOWED_TAGS: [
          'a',
          'abbr',
          'acronym',
          'b',
          'blockquote',
          'br',
          'code',
          'em',
          'i',
          'li',
          'ol',
          'strong',
          'ul'
        ],
        ALLOWED_ATTR: ['href', 'src'],
        ALLOWED_URI_REGEXP:
          /^(?:(?:(?:f|ht)tps?|mailto|tel):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i
      }).replaceAll('\n', '<br />');
    }
    return null;
  } else {
    return null;
  }
});

const fixedHrefsComment = computed(() => {
  return addHrefSchemes(sanitizedComment.value);
});

const hasObservers = computed(() => {
  return props.document?.observers?.length > 0 || false;
});

const documentStartTime = computed(() => {
  if (props.document) {
    return props.document.startTime;
  }
  return undefined;
});

const documentComment = computed(() => {
  if (props.document) {
    return props.document.comment;
  }
  return undefined;
});

function scroll() {
  setTimeout(() => {
    const documentInfo = document.getElementById('document-info');
    if (documentInfo) {
      documentInfo.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }, 200);
}

function setInitialMessage() {
  initialMessage.value = message.value;
}

function updateDocumentMessage() {
  if (message.value !== initialMessage.value) {
    emit('update-signature-message', message.value);
  }
}

function showMessageField() {
  toggleMessageField.value = !toggleMessageField.value;
  nextTick(() => {
    addMessageTextArea.value.focus();
  });
}

async function download() {
  try {
    await downloadDocumentPdf(
      signKey.value,
      props.document?.documentName,
      props.document?.documentStatus,
      props.document?.documentId
    );
  } catch (error) {
    console.error('Error downloading document ', error);
  }
}

function goBackToOverview() {
  const tabStatus = sessionStorage.getItem('filterStatus');
  if (tabStatus !== 'undefined') {
    return router.push({ name: 'dashboard', query: { status: tabStatus } });
  }
  return router.push({ name: 'dashboard' });
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

:deep(.v-expansion-panel-title__overlay) {
  background-color: white;
  height: 12px;
}
.expansion-panel-title {
  padding: 0px !important;
  min-height: 48px !important;
}

.expansion-panel-title .v-expansion-panel-title__overlay .container {
  margin-top: 20px;
  padding: 16px;
  max-width: 100%;
  border: 1px solid $grey-border;
  border-radius: 7px;
}

.mobile-container {
  margin-top: 12px;
  padding: 16px;
  max-width: 100%;
  border: 1px solid $grey-border;
  border-radius: 7px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  font-feature-settings: 'liga' off;
  color: black;
}

.sub-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7d7d7d;
  margin-bottom: 12px;
}

.add-message-button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  @apply text-primary;
}

.button-container {
  width: 380px;
  padding: 0;
}

.observer-panel-content :deep(.v-expansion-panel-text__wrapper) {
  @apply py-1;
}

.signees-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding-top: 0px;
  padding-left: 2px;
  padding-right: 0 !important;
}

.signees-panel-content :deep(.v-expansion-panel-text__wrapper) {
  padding-top: 0px;
  // @apply py-1;
}

.signees-ordered-panel-content :deep(.v-expansion-panel-content__wrap) {
  padding-left: 0;
  margin-top: 16px;
  padding-right: 0 !important;
}

.signees-ordered-panel-content :deep(.v-expansion-panel-text__wrapper) {
  @apply py-1;
}

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

.verification-badge-details {
  max-width: 184px;
  font-size: 13px;
}

.tooltip-link {
  text-decoration: none;
}

.group-container {
  padding-right: 12px;
  background-color: #f7f8fa;
  margin-bottom: 12px;
}

.v-textarea :deep(.v-field__clearable .v-icon) {
  color: rgb(0, 82, 255);
  opacity: 1;
}

#message-preview :deep(a) {
  color: rgb(0, 82, 255);
  text-decoration: underline;
}
</style>

<style>
#message-preview ul {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 10px;
  color: #333;
}

#message-preview ol {
  list-style-type: decimal;
  margin-left: 20px;
  padding-left: 10px;
  color: #333;
}
</style>
