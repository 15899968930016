<template>
  <v-menu v-model="menu" location="bottom right">
    <!--
      Dynamically inherit slots from parent
      & make all slots from component reusable from parent
    -->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>
    <v-card class="menu-card" min-width="195">
      <v-list class="pa-0 ma-0" density="compact">
        <template
          v-for="(item, index) in items"
          :key="`document-menu-item-${index}`"
        >
          <v-list-item
            v-if="item.visible"
            class="menu-item pl-2 pr-0 h-[16px]"
            link
            @click="item.action"
          >
            <v-list-item-title
              class="document-menu-item-title d-flex flex-row align-center justify-start"
              :class="{ 'error-text': item.color === 'error' }"
              d-flex
              flex-row
              :data-test-id="`document-menu-item-${item.dataTestId}`"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { downloadDocumentPdf } from '@/services/documentService';
import { useEmitter } from '@/composables/useEmitter';
import { useGlobalStore } from '@/stores/global/global';

const props = defineProps({
  document: {
    type: Object,
    default: undefined
  },
  policy: {
    type: Object,
    default: undefined
  },
  signKey: {
    type: String,
    default: undefined
  }
});

defineExpose({ openMenu });

const i18n = useI18n();
const emitter = useEmitter();

const globalStore = useGlobalStore();

const menu = ref(false);

const items = computed(() => {
  return [
    {
      title: i18n.t('buttons.download'),
      visible: !!props.document?.documentUrl,
      dataTestId: 'download',
      action: download
    },
    {
      title: i18n.t('buttons.forward_signature'),
      visible: props.policy?.canForward,
      dataTestId: 'forward',
      action: () => {
        openDocumentDialog('forward');
      }
    },
    {
      title: i18n.t('buttons.add_signatory'),
      visible: props.policy?.canAddSignee,
      dataTestId: 'invite',
      action: () => {
        openDocumentDialog('invite');
      }
    },
    {
      title: i18n.t('buttons.add_observer'),
      visible: props.policy?.canAddObserver,
      dataTestId: 'add-observer',
      action: () => {
        openDocumentDialog('add-observer');
      }
    },
    {
      title: i18n.t('buttons.delete_document'),
      visible: props.policy?.canDelete,
      color: 'error',
      dataTestId: 'delete',
      action: deleteDocument
    },
    {
      title: i18n.t('buttons.withdraw_document'),
      visible: props.policy?.canWithdraw,
      color: 'error',
      dataTestId: 'withdraw',
      action: withdrawDocument
    },
    {
      title: i18n.t('buttons.reject_signature'),
      visible: props.policy?.canReject && !props.policy?.canWithdraw,
      color: 'error',
      dataTestId: 'reject',
      action: rejectSignature
    }
  ];
});

function openMenu() {
  menu.value = true;
}
async function download() {
  await downloadDocumentPdf(
    props.signKey,
    props.document.documentName,
    props.document.documentStatus,
    props.document.documentId
  );
}

function withdrawDocument() {
  emitter.$emit('open-document-withdraw-dialog', {
    documents: [{ ...props.document, policy: props.policy }]
  });
}

function rejectSignature() {
  emitter.$emit('open-reject-dialog');
}

function deleteDocument() {
  emitter.$emit('open-delete-documents-dialog', {
    documents: [props.document]
  });
}

function openDocumentDialog(state) {
  globalStore.documentDialog = true;
  globalStore.documentDialogState = state;
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.menu-card {
  padding: 12px;
  border-radius: 12px;
  border: solid 1px #dcdce2;
}

.v-menu__content {
  border-radius: 12px;
}

.theme--light.v-list-item:hover::before {
  opacity: 0;
}

.document-menu-item-title {
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  min-height: 32px;
  justify-content: center;
  align-content: center;

  &:hover {
    color: $primary-color;
  }
}

.error-text {
  color: $error;
  &:hover {
    color: $error;
  }
}
.menu-item.v-list-item:hover > .v-list-item__overlay {
  opacity: 0 !important;
}
</style>
