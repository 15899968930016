<template>
  <div class="flex flex-col">
    <div
      v-if="['idle', 'progress'].includes(removeSignStatus)"
      class="!p-0 text-black mb-4"
    >
      <I18nT keypath="dialogs.document_dialog.subtitles.remove_signee" />
    </div>
    <DeepSignAlert v-if="removeSignStatus === 'error'" class="mb-6" error>
      <I18nT keypath="errors.remove_signee_error" />
    </DeepSignAlert>

    <div v-if="isLast || isLastInGroup" class="warning-card">
      <div class="flex flex-row justify-center items-center">
        <div class="flex flex-col">
          <i class="fa-solid fa-triangle-exclamation warning-icon" />
        </div>
        <div class="flex flex-col ps-2 warning-text">
          <I18nT v-if="isLast" keypath="banners.remove_last_signee_warning" />
          <I18nT
            v-else-if="isLastInGroup"
            keypath="banners.remove_last_signee_in_group_warning"
          />
        </div>
      </div>
    </div>

    <portal to="document-dialog-actions">
      <VBtnSecondary variant="outlined" @click="emit('closeDialog')">
        <I18nT keypath="buttons.close" />
      </VBtnSecondary>
      <VBtnPrimary
        v-if="['idle', 'progress'].includes(removeSignStatus)"
        ref="removeSigneeButton"
        color="primary"
        data-test-id="remove-signee"
        @click="removeSignee"
      >
        <I18nT keypath="buttons.remove_signee" />
      </VBtnPrimary>
    </portal>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { SignStatus } from '@/types/enums/SignStatus';
import { useSignStore } from '@/stores/sign/sign';
import { useGlobalStore } from '@/stores/global/global';
import { useMessageStore } from '@/stores/message/message';
import { useI18n } from 'vue-i18n';
import type { ComponentState } from '@/types/ui';
import type { SignSignee } from '@/types/SignSignee';
import { onMounted } from 'vue';
import { nextTick } from 'vue';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useEmitter } from '@/composables/useEmitter';

const emit = defineEmits(['closeDialog']);
const { currentDocument } = useDocumentContext();

const signStore = useSignStore();
const globalStore = useGlobalStore();
const messageStore = useMessageStore();
const i18n = useI18n();
const removeSignStatus = ref<ComponentState>('idle');
const removeSigneeButton = ref();
const emitter = useEmitter();

const isLast = computed(() => {
  return (
    currentDocument.value &&
    currentDocument.value.signees?.filter(
      (signee) => signee.signStatus === SignStatus.SIGNED
    ).length ===
      (currentDocument.value?.signees?.length || 0) - 1
  );
});

const isLastInGroup = computed(() => {
  let groupIndex = -1;
  if (signStore.signInfo && globalStore.selectedSignee) {
    groupIndex = findGroupIndex(
      signStore.signInfo.document.signeesOrdered,
      globalStore.selectedSignee.signeeId
    );
  }
  if (groupIndex === -1) return;
  return (
    signStore.signInfo?.document.signeesOrdered[groupIndex].filter(
      (signee) => signee.signStatus === SignStatus.PENDING
    ).length === 1
  );
});

function findGroupIndex(signeesOrdered: SignSignee[][], signeeId: string) {
  for (let i = 0; i < signeesOrdered.length; i++) {
    for (let j = 0; j < signeesOrdered[i].length; j++) {
      if (signeesOrdered[i][j].signeeId === signeeId) {
        return i;
      }
    }
  }
  return -1;
}

async function removeSignee() {
  try {
    removeSignStatus.value = 'progress';
    if (
      currentDocument.value &&
      currentDocument.value.documentId &&
      globalStore.selectedSignee?.signeeId
    ) {
      await signStore.removeSignee(
        currentDocument.value.documentId,
        globalStore.selectedSignee?.signeeId
      );
      removeSignStatus.value = 'success';
      messageStore.showMessage({
        text: i18n.t('snackbars.signee_removed'),
        color: 'success',
        icon: 'fa fa-circle-check'
      });
      emitter.$emit('update-sign-status');
      emit('closeDialog');
    } else {
      removeSignStatus.value = 'error';
    }
  } catch (error) {
    removeSignStatus.value = 'error';
  }
}

onMounted(() => {
  nextTick(() => {
    removeSigneeButton.value.$el.focus();
  });
});
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.warning-icon {
  font-size: 18px;
  color: $warning;
}

.warning-card {
  @apply border rounded border-[#fb8c00] py-2.5 px-3.5 justify-items-center align-middle mb-4;
}

.warning-text {
  color: $warning;
  font-size: 14px;
  line-height: 20px;
}
</style>
