<template>
  <div>
    <div class="grid grid-cols-10 items-end justify-between">
      <div class="mt-2 col-span-7 sm:col-span-8">
        <div v-if="index === 0" class="mb-2">
          {{ i18n.t('labels.user') }}
        </div>
        <div class="flex w-full">
          <EmailSuggestion
            :id="observer.key"
            v-model="observerData.email"
            :signee="observerData"
            data-test-id="observer-email"
            :label="i18n.t('labels.email')"
            :email-to-hide="emailToHide"
            :error-messages="observerData.errorMessage"
            :error="observerData.errorState"
            :rules="emailRules"
            @update:model-value="updateObserver"
          />
        </div>
      </div>
      <div class="grid col-span-3 sm:col-span-2 ml-auto">
        <div class="flex items-end">
          <div>
            <div v-if="index === 0" class="mb-2 ml-4">
              {{ i18n.t('labels.admin') }}
            </div>
            <div class="flex items-center justify-between">
              <v-switch
                v-model="observerData.isAdmin"
                class="ml-3 mt-0 v-size--small"
                data-test-id="observer-is-admin"
                :class="{ active: observerData.isAdmin }"
                hide-details
                inset
                @update:model-value="updateObserver"
                @keydown.enter="handleEnter"
              />
            </div>
          </div>
          <v-btn
            icon
            data-test-id="observer-cancel"
            class="mb-1"
            @click="removeObserver"
            @keydown.enter="removeObserver"
            @keydown.space="removeObserver"
          >
            <v-icon> fa fa-close </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Global
import { ref, computed, watchEffect, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useValidationRules } from '@/composables/useValidationRules';
const { emailRules } = useValidationRules();
import type { Observer } from '@/types/Observer';

const props = defineProps({
  index: { type: Number, required: true },
  observer: {
    type: Object as PropType<Observer>,
    required: true
  },
  observers: { type: Array as PropType<Observer[]>, required: true },
  emailToHide: { type: String, default: '' }
});

const i18n = useI18n();

const emit = defineEmits(['remove-observer', 'update-observer']);

const observerData = ref({ ...props.observer });

// computed property for checking email uniqueness
const isEmailDuplicate = computed(() => {
  const email = observerData.value.email;
  return (
    email &&
    props.observers.filter(
      (obs) => obs.email?.toLowerCase() === email?.toLowerCase()
    ).length > 1
  );
});

// Show the error message manually when isEmailDuplicate
const errorMessage = computed(() => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const email = observerData.value.email || '';
  if (email === '') {
    return null;
  } else if (isEmailDuplicate.value) {
    return 'Duplicate';
  } else if (!emailRegex.test(email)) {
    return 'E-mail must be valid';
  } else {
    return null;
  }
});

function removeObserver() {
  emit('remove-observer', props.index);
}

function handleEnter() {
  emit('update-observer', {
    observer: observerData.value,
    error: observerData.value.errorState
  });
  observerData.value.isAdmin = !observerData.value.isAdmin;
}

function updateObserver() {
  emit('update-observer', {
    observer: observerData.value,
    error: observerData.value.errorState
  });
}

// trigger email validation on observerData.email or props.observers change
watchEffect(() => {
  const error = errorMessage.value;
  observerData.value.errorState = !!error;
  if (error) {
    observerData.value.errorMessage = error;
  }
  emit('update-observer', {
    observer: observerData.value,
    error: observerData.value.errorState
  });
});
</script>

<style scoped lang="scss">
.v-switch.active :deep(.v-switch__track) {
  background-color: #adc7ff !important;
}
.v-switch.active :deep(.v-switch__thumb) {
  background-color: #0052ff;
}
</style>
