<template>
  <div class="flex justify-between items-center w-full gap-2 h-[40px]">
    <!-- FILE NAME -->
    <div class="truncate">{{ fileName }}</div>
    <!-- /FILE NAME -->

    <!-- ICONS -->
    <div class="flex items-center">
      <!-- Unsuported state -->
      <div
        v-if="file.status === UploadStatus.UNSUPPORTED"
        class="flex flex-col items-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="error">
              far fa-file-slash
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="text-caption">
              {{ i18n.t('texts.unsupported_file') }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
      <div
        v-else-if="file.status === UploadStatus.EXCLUDED || file.isTooLarge"
        class="flex flex-col items-end"
      >
        <v-menu location="left top" max-width="300" open-on-hover open-on-click>
          <template #activator="{ props: ActivatorProps }">
            <v-icon v-bind="ActivatorProps" color="#F99C2C">
              fal fa-triangle-exclamation
            </v-icon>
          </template>
          <v-card>
            <v-card-text class="text-caption">
              {{
                file.apiError
                  ? i18n.t(`errors.api.${file.apiError}`)
                  : i18n.t('texts.file_too_large_short')
              }}
            </v-card-text>
          </v-card>
        </v-menu>
      </div>

      <span class="text-black flex whitespace-nowrap ml-2">
        {{ fileSize }}
      </span>

      <!-- Pending state -->
      <div
        v-if="file.status === UploadStatus.PENDING"
        class="d-flex flex-column align-end"
      >
        <v-icon> fal fa-hourglass-start </v-icon>
      </div>
      <!-- Remove file btn -->
      <div>
        <v-btn
          v-if="
            file.status === UploadStatus.READY ||
            file.status === UploadStatus.UNSUPPORTED
          "
          icon
          size="small"
          variant="text"
          @click.stop="removeFile"
        >
          <v-icon color="philippineSilver"> fal fa-close </v-icon>
        </v-btn>
        <div
          v-else
          class="flex flex-col items-center justify-center mb-2 mx-2 mt-1"
        >
          <!-- Success state -->
          <v-menu
            v-if="file.status === UploadStatus.UPLOADED"
            location="left top"
            max-width="300"
            open-on-hover
            open-on-click
          >
            <template #activator="{ props: ActivatorProps }">
              <v-icon v-bind="ActivatorProps" color="success">
                fal fa-circle-check
              </v-icon>
            </template>
            <v-card>
              <v-card-text class="text-caption">
                {{ i18n.t('texts.correctly_uploaded_file') }}
              </v-card-text>
            </v-card>
          </v-menu>
          <!-- Uploading state -->
          <v-progress-circular
            v-else-if="file.status === UploadStatus.PROGRESS"
            color="primary"
            size="16"
            indeterminate
            class="mt-1.5"
            width="1"
          />
          <!-- Error state -->
          <v-menu
            v-if="file.status === UploadStatus.ERROR"
            location="left top"
            max-width="300"
            open-on-hover
            open-on-click
          >
            <template #activator="{ props: ActivatorProps }">
              <v-icon v-bind="ActivatorProps" color="error">
                fal fa-triangle-exclamation
              </v-icon>
            </template>
            <v-card>
              <v-card-text class="text-caption">
                {{
                  file.apiError
                    ? i18n.t(`errors.api.${file.apiError}`)
                    : i18n.t('texts.file_too_large_short')
                }}
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>
    <!-- /ICONS -->
  </div>
</template>

<script setup lang="ts">
import { UploadStatus } from '@/types/enums/UploadStatus.ts';
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { humanFileSize } from '@/composables/useUtils';
import type { FileType } from '@/types/FileType';

const props = defineProps({
  file: {
    type: Object as PropType<FileType>,
    default: undefined
  },
  excluded: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['remove-uploaded-file']);

const i18n = useI18n();

const fileSize = computed(() => {
  if (props.file && props.file.file?.size) {
    return humanFileSize(props.file.file?.size);
  } else return undefined;
});

const fileName = computed(() => {
  if (props.file && props.file.file) {
    return props.file.file.name;
  } else return undefined;
});

function removeFile() {
  emit('remove-uploaded-file', props.file);
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.tooltip {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2) !important;
}
</style>
