<template>
  <VCard class="signing-card" flat>
    <!-- To approve card -->
    <div
      v-if="
        signStatus === SignStatus.PENDING || signStatus === SignStatus.ON_HOLD
      "
      class="flex flex-row"
    >
      <div class="flex flex-col w-full">
        <div class="mb-2">
          <!-- TITLE -->
          <div class="flex flex-row h-[24px] rounded-0">
            {{ i18n.t('titles.approving_as') }}
          </div>
          <!-- /TITLE -->

          <!-- CARD MENU -->
          <DocumentMenu
            ref="documentMenu"
            :document="signStore.signInfo?.document"
            :policy="isSignatureTypeSeal ? {} : policy"
            :sign-key="signKey"
          >
            <template #activator="{ props: ActivatorProps }">
              <v-btn
                v-bind="ActivatorProps"
                style="position: absolute; right: 4px; top: 8px"
                icon
                data-test-id="signing-card-options"
              >
                <VIcon class="text-gray-500">
                  fa-regular fa-ellipsis-vertical
                </VIcon>
              </v-btn>
            </template>
          </DocumentMenu>
          <!-- /CARD MENU -->

          <!-- APPROVERS  DETAILS -->
          <SignerSignDetails :email="email" :show-signature-level="false" />
          <!-- /APPROVERS  DETAILS -->
        </div>
        <DeepButton
          :action="handleApprove"
          class="mt-2"
          color="primary"
          :loading="signStore.approvingSignatureProcess"
          :text="i18n.t('buttons.approve_now')"
        />
        <DeepButton
          :action="handleRejection"
          class="mt-2"
          :disabled="signStore.approvingSignatureProcess"
          variant="outlined"
          :text="i18n.t('buttons.reject_now')"
        />
      </div>
    </div>
    <!-- Approved card -->
    <SuccessApproverCard v-if="signStatus === SignStatus.APPROVED" />
  </VCard>
  <RejectSignatureProcessDialog />
  <DocumentDialog />
</template>
<script setup lang="ts">
import { useEmitter } from '@/composables/useEmitter.ts';
import { useSignStore } from '@/stores/sign/sign.ts';
import { SignStatus } from '@/types/enums/SignStatus.ts';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/auth-service.ts';
import { useSignContext } from '@/composables/useSignContext.ts';

const i18n = useI18n();
const signStore = useSignStore();
const emitter = useEmitter();
const router = useRouter();
const { policy, isSignatureTypeSeal, signKey } = useSignContext();

const props = defineProps({
  comment: {
    type: String,
    default: undefined
  }
});

const email = computed(() => signStore.signInfo?.email);
const signStatus = computed(() => signStore.signInfo?.signStatus);

function handleApprove() {
  if (signStore.signInfo?.signKey) {
    if (AuthService.isAuthenticated) {
      signStore
        .approveAuthSignProcess({
          signKey: signStore.signInfo?.signKey,
          comment: props.comment
        })
        .then(() => {
          router.push(
            `/document/${signStore.signInfo?.document.documentId}/details`
          );
        });
    } else {
      signStore.approveSignProcess({
        signKey: signStore.signInfo?.signKey,
        comment: props.comment
      });
    }
  }
}
function handleRejection() {
  emitter.$emit('open-reject-signature-process-dialog', true);
}
</script>

<style scoped lang="scss">
@import '@/styles/core/colors';

.signing-card {
  box-sizing: border-box;
  border-radius: 7px;
  background-color: $grey-background;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15) !important;
  border: 1px solid $grey-border;
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 1rem;
}
</style>
