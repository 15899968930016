<template>
  <VApp ref="dropZoneRef">
    <Header :is-open="isOpen"></Header>
    <VNavigationDrawer v-model="isOpen" border="r">
      <RouterView name="sidebar" />
      <div v-if="isOpen" class="absolute right-[-48px] top-[10px] xl:hidden">
        <VBtn
          id="grid-switcher"
          class="view-switcher-btn"
          icon="fa-solid fa-chevrons-left"
          value="grid"
          :data-test-id="`grid-view`"
          size="small"
          rounded="lg"
          @click="emitter.$emit('toggle-sidebar')"
        ></VBtn>
      </div>
      <template #append>
        <div class="mb-2">
          <NewFooter />
        </div>
      </template>
    </VNavigationDrawer>
    <VMain scrollable>
      <RouterView name="default" />
    </VMain>
  </VApp>
</template>

<script setup lang="ts">
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin';
import { onUnmounted, ref } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useDisplay } from 'vuetify';

const deepAdminStore = useDeepAdminStore();
const display = useDisplay();
deepAdminStore.fetchUserInfo();
const isOpen = ref(!display.mobile.value);

const dropZoneRef = ref();
const emitter = useEmitter();

emitter.$on('toggle-sidebar', onToggleSidebar);

function onToggleSidebar() {
  isOpen.value = !isOpen.value;
}

onUnmounted(() => {
  emitter.$off('toggle-sidebar', onToggleSidebar);
});
</script>

<style scoped lang="postcss">
.overlay {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(173, 169, 169, 0.421);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0s 200ms,
    opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.overlay-placeholder {
  display: flex;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03))
    drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 471.19px;
  background-color: #ffff;
  border-width: 2px;
  border-color: $grey;
  border-radius: 0.5rem;
  text-align: center;
  padding: 24px;
}

.overlay-text {
  font-size: 1.125rem; /* 16px */
  line-height: 1.75rem; /* 24px */
  font-weight: 500;
}

.file-plus-icon {
  color: $primary-color;
  font-size: 50px;
}

.onDropZone {
  opacity: 1;
  visibility: visible; /* visible when in dropzone */
  transition:
    visibility 0s,
    opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
