import { pixelsToPoints } from '@/services/pdfService.ts';
import type { PDFPageProxy } from '@/services/signatureService.ts';

/**
 * Calculates the coordinates and dimensions for a signature placeholder on a document page for the backend with tacking into account the possibility of a pdf page containing cropBox coordinates.
 *
 * @param {Object} signaturePosition - The object containing signature position details including x, y, width, height, and pageNumber.
 * @param {string} signeeId - The unique identifier of the signee associated with the signature placeholder.
 * @param {PDFPageProxy} pageDetails - The details of the document page, including its viewport and scale information.
 * @return {Object} An object containing the calculated x and y coordinates, width, height, and a Boolean indicating if a crop box case is applied.
 */
export async function calculateCoordinates(
  signaturePosition,
  signeeId: string,
  pageDetails
) {
  const { view } = pageDetails;
  const page = document.getElementById(
    `id-${parseInt(signaturePosition.pageNumber)}`
  );
  const pageWidth = window.getComputedStyle(page).getPropertyValue('--width');
  const pageHeight = window.getComputedStyle(page).getPropertyValue('--height');
  const placeHolder = document.getElementById(`signature-preview-${signeeId}`);
  const placeHolderLeft = parseInt(placeHolder.style.left);
  const viewport = pageDetails.getViewport({
    scale: Math.min(
      parseFloat(
        parseInt(pageWidth) / pageDetails.getViewport({ scale: 1 }).width
      ),
      parseFloat(
        parseInt(pageHeight) / pageDetails.getViewport({ scale: 1 }).height
      )
    )
  });
  const { scale, rotation } = viewport;
  const placeHolderTop = getPlaceHolderTopPos(
    signaturePosition?.pageNumber || 1,
    placeHolder
  );
  const isCropBoxCase = view[0] > 0 || view[1] > 0;
  const isRotationLandscape =
    Math.abs(rotation) === 90 || Math.abs(rotation) === 270;
  const cropBoxCalculatedX = placeHolderLeft / scale + view[0];
  const pageHeightRotated = isRotationLandscape ? view[2] : view[3];
  const cropBoxCalculatedY = Math.abs(
    pageHeightRotated -
      placeHolderTop / scale -
      placeHolder!.getBoundingClientRect().height / scale
  );
  return {
    x: isCropBoxCase ? cropBoxCalculatedX : pixelsToPoints(signaturePosition.x),
    y: isCropBoxCase ? cropBoxCalculatedY : pixelsToPoints(signaturePosition.y),
    width: isCropBoxCase
      ? placeHolder!.getBoundingClientRect().width / scale
      : pixelsToPoints(signaturePosition.width),
    height: isCropBoxCase
      ? placeHolder!.getBoundingClientRect().height / scale
      : pixelsToPoints(signaturePosition.height),
    isCropBoxCase
  };
}

/**
 * Calculates the top position of a placeholder element considering page count and page rotation
 *
 * @param {number} pageNumber - The current page number where the placeholder is located.
 * @param {HTMLElement} placeHolder - The placeholder element whose top position needs to be determined.
 * @return {number} The adjusted top position of the placeholder element.
 */
function getPlaceHolderTopPos(
  pageNumber: number,
  placeHolder: HTMLElement
): number {
  const pdfPreviewElement = document.getElementById('pdf-preview');
  const pageNodes =
    pdfPreviewElement?.getElementsByClassName('pageplaceholder') || [];
  const isMultiPage = pageNodes.length > 1;

  const MARGIN_BOTTOM = 16;
  // we need to take the page count of pages and the margin bottom into the equation as the layerY coordinates are taken relative to the current page and thus are not usable
  let tempPageHeight = 0;
  for (let i = 0; i <= pageNodes.length; i++) {
    if (i === pageNumber - 1) {
      break;
    }
    tempPageHeight +=
      pageNodes[i].getBoundingClientRect().height + MARGIN_BOTTOM;
  }

  return isMultiPage
    ? parseInt(placeHolder.style.top) - tempPageHeight
    : parseInt(placeHolder.style.top);
}

/**
 * Computes and returns the transformed position details for a given PDF page.
 *
 * @param {HTMLElement} currentPage - The DOM element representing the current page.
 * @param {PDFPageProxy} pageDetails - The PDF page details proxy used to retrieve viewport information.
 * @return {Object} An object containing the transformed position details:
 *                  - `isCropBoxCase` {boolean}: Indicates if the crop box case is applied.
 *                  - `scale` {number}: The scaling factor calculated for the page.
 *                  - `view` {Array<number>}: The view box parameters of the PDF page.
 */
export function getPdfPageInfo(
  currentPage: HTMLElement,
  pageDetails: PDFPageProxy
) {
  const pageWidth = window
    .getComputedStyle(currentPage)
    .getPropertyValue('--width');

  const pageHeight = window
    .getComputedStyle(currentPage)
    .getPropertyValue('--height');

  const viewport = pageDetails.getViewport({
    scale: Math.min(
      pageWidth / pageDetails.getViewport({ scale: 1 }).width,
      pageHeight / pageDetails.getViewport({ scale: 1 }).height
    )
  });
  const { scale } = viewport;
  const { view } = pageDetails;
  const isCropBoxCase = view[0] > 0 || view[1] > 0;
  return {
    isCropBoxCase,
    scale,
    view
  };
}
