<template>
  <DeepDialog
    v-model="showDialog"
    fullscreen-on-mobile
    width="600"
    :title="i18n.t('titles.send_your_feedback')"
    @keydown.esc="closeDialog"
  >
    <template #content>
      <!-- FORM -->
      <v-form ref="feedbackForm" class="mt-4" @submit.prevent>
        <v-container class="px-0">
          <v-text-field
            v-model.trim="form.email"
            autocapitalize="off"
            autocomplete="off"
            autocorrect="off"
            type="email"
            :autofocus="form.email === null"
            class="pb-6 email-input"
            :hint="i18n.t('hints.we_may_contact_you_for_further_questions')"
            :label="i18n.t('labels.email')"
            name="field-form-feedback-email"
            variant="outlined"
            persistent-hint
            :placeholder="i18n.t('placeholders.contact_email')"
            :rules="emailRules"
          />

          <v-textarea
            ref="feedbackMessage"
            v-model="form.message"
            :autofocus="form.email !== null"
            :label="i18n.t('labels.feedback_message')"
            name="field-feedback-message"
            variant="outlined"
            :placeholder="i18n.t('placeholders.enter_your_feedback')"
            :rules="feedbackMessageRules"
          />
        </v-container>
      </v-form>
      <!-- /FORM -->
    </template>
    <template #actions>
      <VBtnSecondary :text="i18n.t('buttons.cancel')" @:click="closeDialog" />
      <VBtnPrimary
        :loading="deepAdminStore.sendEmailRequestPending"
        :text="i18n.t('buttons.send_feedback')"
        @click="submitForm"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import AuthService from '@/services/auth-service';
import { useDeepAdminStore } from '@/stores/deepadmin/deepadmin.ts';
import { EmailRequestType } from '@/types/deepadmin/enums/EmailRequestType.ts';
import { ref, reactive, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { useValidationRules } from '@/composables/useValidationRules';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const deepAdminStore = useDeepAdminStore();
const i18n = useI18n();
const emitter = useEmitter();
const showDialog = ref(false);
const feedbackForm = ref();
const form = reactive({
  email: '',
  message: ''
});

const { emailRules, feedbackMessageRules } = useValidationRules();

emitter.$on('open-feedback-dialog', openDialog);

onUnmounted(() => {
  emitter.$off('open-feedback-dialog', openDialog);
});

function openDialog() {
  if (AuthService.user?.email) {
    form.email = AuthService.user?.email;
  }
  showDialog.value = true;
}

function closeDialog() {
  feedbackForm.value.reset();
  form.email = null;
  form.message = null;
  showDialog.value = false;
}

async function submitForm() {
  const isFormValid = await feedbackForm.value.validate();
  if (isFormValid && form.email && form.message) {
    await deepAdminStore.sendEmailRequest({
      contact: form.email,
      text: form.message,
      type: EmailRequestType.FEEDBACK
    });
    closeDialog();
  }
}
</script>

<style lang="scss" scoped>
:deep(.v-text-field.v-text-field--enclosed .v-text-field__details) {
  padding-left: 0 !important;
}

.email-input :deep(.v-input__details) {
  padding: 0%;
}
</style>
