/**
 * Constants file
 * Usage example:
 * import { constants } from '@/constants/constants'
 */

export const constants = {
  FILE_MAX_SIZE: 40000000,
  DRAWER_WIDTH: '484px',
  DRAWER_WIDTH_TABLET: '444px',
  SNACKBAR: {
    DURATION: 3000
  },
  EXPAND_SCROLL_OPTIONS: {
    block: 'nearest',
    inline: 'nearest'
  },
  SUPPORTED_AUTOGRAPH_TYPES: ['image/png', 'image/jpeg'],
  SUPPORTED_DOCUMENT_TYPES: [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  DEEPSIGN_API_HANDLED_ERRORS: [
    'avScan.virusFound',
    'no_subscription_found',
    'subscription_signature_authorization_failed',
    'error.pdf.password.protected',
    'error.pdf.certified.noChangeAllowed',
    'error.pdf.signatureFieldLock.noChangeAllowed',
    'error.pdf.read.failed',
    'error.document.file.tooBig',
    'error.sign.failed',
    'error.sign.user.timeout',
    'error.sign.user.cancel',
    'error.sign.user.password.invalid',
    'error.sign.user.otp.invalid',
    'error.sign.user.serialNumber.mismatch',
    'error.sign.user.onboarding.incomplete',
    'error.sign.document.invalid',
    'error.subscription.failed',
    'error.subscription.observer.unavailable',
    'error.predefined.multiple.signature.fields'
  ],
  SEAL_POLICIES_TO_OMIT: ['canAddSignee', 'canForward', 'canAddObserver'],
  SIGNATURE_SIZE: {
    XS: {
      HEIGHT: 18,
      WIDTH: 35
    },
    S: {
      HEIGHT: 77,
      WIDTH: 146
    },
    M: {
      HEIGHT: 112,
      WIDTH: 212
    },
    L: {
      HEIGHT: 137,
      WIDTH: 260
    }
  },
  SIGNATURE_ASPECT_RATIO: 260 / 137,
  SIGNING_METHODS: {
    GUEST: 'guest'
  },
  UNTRANSLATED_ERROR_MESSAGES: {
    DOCUMENT_NOT_FOUND: 'Document not found.'
  },
  SIGN_ERRORS_KEY_ORDER: [
    'blocked_country',
    'blocked_nationality',
    'blocked_domicile',
    'unsuitable_domicile',
    'bearer_not_of_legal_age',
    'internal_error',
    'api_not_reachable',
    'document_type',
    'document_invalidated',
    'identification_stale',
    'document_expired',
    'visual_verification'
  ],
  SIGN_ERROR_CONCURRENT_MODIFICATION:
    'error.sign.document.concurrent.modification'
};
