<template>
  <v-navigation-drawer
    v-model="globalStore.drawer"
    :permanent="$vuetify.display.lgAndUp"
    location="right"
    width="484"
    touchless
    border="none"
  >
    <template #prepend>
      <VToolbar color="white" class="border-b">
        <template #prepend>
          <div class="flex items-center justify-center gap-2">
            <div class="flex items-center gap-3 pl-4">
              <RouterLink :to="{ path: '/' }">
                <DeepAppLogo logo="deepsign" />
              </RouterLink>
              <span class="text-gray-300">|</span>
              <img alt="Swiss Made" height="16px" src="/svg/swiss_made.svg" />
            </div>
          </div>
        </template>
        <template #append>
          <UserAccountMenu />
        </template>
      </VToolbar>
    </template>

    <!-- DRAWER CONTENT -->
    <div class="px-4 md:pb-6 pt-4">
      <template v-if="isCreate && currentDocument && createStore.document">
        <CreateCard />
        <PositionAndMessageCard
          @enforce-authority-service="createStore.enforceAuthorityService"
        />
        <!-- ACTIONS -->
        <div class="w-full">
          <DeepButton
            ref="sendInvitationsButton"
            data-test-id="continue"
            :action="proceed"
            :disabled="isInvalid"
            :loading="
              startDocumentPending ||
              clearingEmptyInvitations ||
              clearingEmptyObservers
            "
            :text="buttonText"
          />
          <DeepButton
            v-if="
              currentDocument.documentUrl &&
              currentDocument.documentStatus !== DocumentStatus.DRAFT
            "
            class="mt-2"
            :action="download"
            variant="outlined"
            :text="i18n.t('buttons.download')"
          />
        </div>
        <!-- /ACTIONS -->
        <ConfirmationDialog ref="confirmationDialog" />
      </template>
      <div v-else class="mt-3">
        <SealingCard
          v-if="route.name === 'document-seal'"
          :signatures="currentDocument.signees"
          :document="currentDocument"
          :can-sign="canSign"
        />
        <SigningCard
          v-else-if="shouldSign && !shouldApprove"
          :can-add-autograph="canAddAutograph"
          :can-sign="canSign"
          :document="currentDocument"
          :email="userEmail"
          :is-signed-by-user="isSignedByUser"
          :sign="sign"
          :sign-button-enabled="canSign"
          :signatures="currentDocument.signees"
          @update-authority-service="setAuthorityService"
          @update-uploaded-files="setUploadedFiles"
        />
        <ApproverCard v-else-if="shouldApprove" :comment="message" />
        <ObserverCard v-else>
          <!-- ON HOLD ALERT -->
          <v-alert
            v-if="
              currentDocument.documentStatus === DocumentStatus.IN_PROGRESS &&
              isUserPartOfSignees
            "
            class="deep-caption my-4"
            variant="outlined"
            type="info"
          >
            {{
              hasOnlySignees
                ? i18n.t('texts.on_hold_hint')
                : i18n.t('texts.on_hold_generic_hint')
            }}
          </v-alert>
          <!-- /ON HOLD ALERT -->
        </ObserverCard>
        <DocumentInfo
          :document="currentDocument"
          :signees="currentDocument.signees"
          :sing-key="signKey"
          @update-signature-message="updateSignatureComment"
        />
        <ConfirmationDialog ref="signConfirmationDialog" />
        <TimeOutDialog ref="timeOutDialog" />
        <SignatureCancelledDialog />
        <SignErrorDialog />
        <SignatureEdition ref="signatureEdition" />
      </div>
    </div>

    <!-- /DRAWER CONTENT -->

    <template v-if="$vuetify.display.mdAndDown" #append>
      <v-footer style="height: 60px; background-color: white">
        <DeepButton
          :action="
            () => {
              globalStore.drawer = !globalStore.drawer;
            }
          "
          variant="outlined"
          :text="i18n.t('buttons.close')"
        />
      </v-footer>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import AuthService from '@/services/auth-service';
import { DeepAppLogo } from '@deepcloud/deep-ui-lib';
import SignatureService from '@/services/signatureService';
import { AuthorityService } from '@/types/enums/AuthorityService.ts';
import { DocumentStatus } from '@/types/enums/DocumentStatus';
import { Jurisdiction } from '@/types/enums/Jurisdiction.ts';
import { SignStatus } from '@/types/enums/SignStatus';
import { SignatureMode } from '@/types/enums/SignatureMode.ts';
import { base64ToFile } from '@/composables/useUtils';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { storeStartedDocument } from '@/api/deepsign/start';
import { useEmitter } from '@/composables/useEmitter.ts';
import { useI18n } from 'vue-i18n';
import { useMessageStore } from '@/stores/message/message';
import { usePreferencesStore } from '@/stores/deepadmin/preferences';
import { useRouter, useRoute } from 'vue-router';
import { useSignStore } from '@/stores/sign/sign';
import { useSubscriptionStore } from '@/stores/subscription/subscription';
import { useGlobalStore } from '@/stores/global/global';
import { useSignContext } from '@/composables/useSignContext';
import { downloadDocumentPdf } from '@/services/documentService';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useDisplay } from 'vuetify';
import { useEmailService } from '@/components/inputs/Emailsuggestion/useEmailService';
import { useTerms } from '@/composables/useTerms';
import { useCreateStore } from '@/stores/create.ts';
import type { Document } from '@/types/Document.ts';
import { SigneeType } from '@/types/enums/SigneeType.ts';
const createStore = useCreateStore();
const { isDocumentAuthRoute, currentDocument } = useDocumentContext();
const { getTranslatedTerms } = useTerms();
const agreement = ref(false);
const authorityService = ref(null);
const autographData = ref(null);
const clearingEmptyInvitations = ref(false);
const clearingEmptyObservers = ref(false);
const confirmationDialog = ref();
const display = useDisplay();
const globalStore = useGlobalStore();
const emitter = useEmitter();
const i18n = useI18n();
const message = ref('');
const messageStore = useMessageStore();
const pollInterval = ref(null);
const preferencesStore = usePreferencesStore();
const router = useRouter();
const route = useRoute();
const signConfirmationDialog = ref();
const signStore = useSignStore();
const signeeFields = ref([]);
const startDocumentPending = ref(false);
const subscriptionStore = useSubscriptionStore();
const useDefaultAutograph = ref(false);
const uploadedFiles = ref([]);
const { isGuest, userEmail, isSignatureTypeSeal, signKey } = useSignContext();
const { saveRecentlyUsedEmails } = useEmailService(
  (currentDocument.value as Document).companyId
);

defineEmits(['update-sign-status']);

emitter.$on('change-autograph', updateChangeAutograph);
emitter.$on('stop-sign-status-polling', stopPolling);
emitter.$on('update-agreement', updateAgreementStatus);
emitter.$on('update-attachments-option', createStore.updateAttachmentsAllow);
emitter.$on('update-authority-service', createStore.updateAuthorityService);
emitter.$on('update-autograph-data', updateSignatureData);
emitter.$on('update-signature-level', createStore.updateSignatureLevel);
emitter.$on('update-signature-message', createStore.updateSignatureMessage);
emitter.$on('update-signee-fields', updateSigneeFields);

globalStore.drawer = display.lgAndUp.value;

onMounted(() => {
  useDefaultAutograph.value = signStore.signInfo?.defaultAutographUrl !== null;
});

onUnmounted(() => {
  emitter.$off('change-autograph', updateChangeAutograph);
  emitter.$off('stop-sign-status-polling', stopPolling);
  emitter.$off('update-agreement', updateAgreementStatus);
  emitter.$off('update-attachments-option', createStore.updateAttachmentsAllow);
  emitter.$off('update-authority-service', createStore.updateAuthorityService);
  emitter.$off('update-autograph-data', updateSignatureData);
  emitter.$off('update-signature-level', createStore.updateSignatureLevel);
  emitter.$off('update-signature-message', createStore.updateSignatureMessage);
  emitter.$off('update-signee-fields', updateSigneeFields);
});

const isDraftMode = computed(() => route.name === 'document-create');

const isAdvancedEidas = computed(() => {
  return (
    currentDocument.value &&
    currentDocument.value.signatureMode === SignatureMode.ADVANCED &&
    currentDocument.value.jurisdiction === Jurisdiction.EIDAS
  );
});

const isBasic = computed(() => {
  return (
    currentDocument.value &&
    currentDocument.value.signatureMode === SignatureMode.TIMESTAMP
  );
});

const isCreate = computed(() => {
  return (
    currentDocument.value &&
    currentDocument.value.documentStatus === DocumentStatus.DRAFT
  );
});

const isInvalid = computed(() => {
  // Check we have at least one signee
  if (!createStore.hasSignees) return true;

  // If we only have some empty fields
  if (createStore.signeesOrderedFlat.some((signee) => signee.email === null))
    return true;

  // Check all strings are valid emails
  let hasInvalidStrings = false;
  if (createStore.signeesOrderedFlat) {
    createStore.signeesOrderedFlat
      .filter((s) => {
        return s.email !== null && s.email !== '';
      })
      .forEach((signee) => {
        if (validateEmail(signee.email)) {
          hasInvalidStrings = true;
        }
      });
  }
  return hasInvalidStrings;
});

const isMissingSubscription = computed(() => {
  return !subscriptionStore.hasValidSubscription;
});

const canAddAutograph = computed(() => {
  const mySignature = SignatureService.getSigneeByEmail(
    AuthService.user?.email,
    currentDocument.value.signees
  );
  return (
    canSign.value &&
    (mySignature?.autographPosition !== null ||
      signStore.signInfo?.canModifyAutographPosition)
  );
});

const canSign = computed(() => {
  if (isGuest.value) {
    return signStore.signInfo?.signStatus === SignStatus.PENDING;
  }
  if (AuthService.isAuthenticated) {
    return SignatureService.canSign(
      AuthService.user?.email,
      currentDocument.value
    );
  }
  return false;
});

const destination = computed(() => {
  if (SignatureService.onlyMe(AuthService.user?.email, currentDocument.value)) {
    return `/document/${currentDocument.value.documentId}/sign`;
  }
  return `/document/${currentDocument.value.documentId}/invitations-sent`;
});

const confirmationTitle = computed(() => {
  if (
    createStore.hasEmptyObservers &&
    createStore.hasEmptySignees &&
    createStore.hasEmptyApprovers
  ) {
    // Empty approvers signees and observers
    return i18n.t('titles.undefined_approvers_signees_and_observers');
  } else if (createStore.hasEmptySignees && createStore.hasEmptyApprovers) {
    // Empty signees and approvers
    return i18n.t('titles.undefined_approvers_and_signees');
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptyObservers) {
    // Empty approvers and observers
    return i18n.t('titles.undefined_approvers_and_observers');
  } else if (createStore.hasEmptyObservers && createStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t('titles.undefined_signees_and_observers');
  } else if (createStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees');
  } else if (createStore.hasEmptyApprovers) {
    // Only empty approvers
    return i18n.t('titles.undefined_approvers');
  } else if (createStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers');
  } else {
    return i18n.t('titles.no_observers_subscription_title');
  }
});

const confirmationText = computed(() => {
  if (
    createStore.hasEmptyObservers &&
    createStore.hasEmptySignees &&
    createStore.hasEmptyApprovers
  ) {
    // Empty approvers signees and observers
    return i18n.t(
      'titles.undefined_approvers_and_signees_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptySignees && createStore.hasEmptyApprovers) {
    // Empty signees and approvers
    return i18n.t(
      'titles.undefined_approvers_and_signees_confirmation_request'
    );
  } else if (createStore.hasEmptyApprovers && createStore.hasEmptyObservers) {
    // Empty approvers and observers
    return i18n.t(
      'titles.undefined_approvers_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptyObservers && createStore.hasEmptySignees) {
    // Empty signees and observers
    return i18n.t(
      'titles.undefined_signees_and_observers_confirmation_request'
    );
  } else if (createStore.hasEmptySignees) {
    // Only empty signees
    return i18n.t('titles.undefined_signees_confirmation_request');
  } else if (createStore.hasEmptyApprovers) {
    // Only empty approvers
    return i18n.t('titles.undefined_approvers_confirmation_request');
  } else if (createStore.hasEmptyObservers) {
    // Only empty observers
    return i18n.t('titles.undefined_observers_confirmation_request');
  } else {
    return i18n.t('titles.no_observers_subscription_dialog_ask_owners');
  }
});

const buttonText = computed(() => {
  if (
    SignatureService.onlyMe(AuthService.user?.email, currentDocument.value) &&
    currentDocument.value?.signeesOrdered.flat().length === 1
  ) {
    return i18n.t('buttons.continue');
  }
  return i18n.t('buttons.send_invitations');
});

const signStatus = computed(() => {
  return signStore.signInfo?.signStatus;
});

watch(
  () => signStatus.value,
  (newValue, oldValue) => {
    if (newValue !== SignStatus.IN_PROGRESS) {
      stopPolling();
      emitter.$emit('close-sign-pending-dialog');
      // If signStatus is 'pending', we have to check if there is an error and display it
      if (newValue === SignStatus.PENDING) {
        if (!signStore.signInfo?.lastError) {
          return;
        } else if (
          signStore.signInfo.lastError?.messageId === 'error.sign.user.timeout'
        ) {
          emitter.$emit('show-timeout-dialog');
        } else if (
          signStore.signInfo.lastError?.messageId === 'error.sign.user.cancel'
        ) {
          emitter.$emit('show-signature-cancelled-dialog', {
            authorityService: authorityService.value
          });
        } else {
          emitter.$emit('show-sign-error-dialog', {
            errorKey: signStore.signInfo.lastError?.messageId,
            authorityService: authorityService.value
          });
        }
      }
      // If signStatus is 'signed' check if we need to redirect to correct page
      if (newValue === SignStatus.SIGNED) {
        stopPolling();
        const signature = document.getElementById(
          `signature-preview-${signStore.userSigneeId}`
        );
        if (signature) {
          signature.parentNode.removeChild(signature);
        }
        // Need to do this check to avoid showing the success message twice
        if (newValue !== oldValue) {
          if (!isGuest.value && isSignedByUser.value && currentDocument.value) {
            router.push({
              path: `/document/${currentDocument.value.documentId}/details`,
              query: { ...route.query }
            });
          }
          emitter.$emit('update-sign-status');

          messageStore.showMessage({
            key: isSignatureTypeSeal.value
              ? 'snackbars.sealed_text'
              : 'snackbars.signed_text',
            color: 'success',
            icon: 'fa fa-circle-check'
          });
        }
      }
    }
  },
  {
    deep: true
  }
);

function validateEmail(email) {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return !reg.test(email);
}

function updateSigneeFields(value) {
  signeeFields.value = value;
}

function updateSignatureComment(newComment) {
  message.value = newComment;
}

function saveRecentlyUsedEmailsToPreferences() {
  if (isDraftMode.value) {
    try {
      saveRecentlyUsedEmails(currentDocument.value!.signeesOrdered.flat());
    } catch (e) {
      console.error('Save recently used emails failed ', e);
    }
  }
}

async function startDocumentProcess() {
  try {
    startDocumentPending.value = true;
    await storeStartedDocument(currentDocument.value!.documentId);
    currentDocument.value.documentStatus = DocumentStatus.IN_PROGRESS;
    startDocumentPending.value = false;
    // emit open invitations dialog
    if (
      createStore.initiatorAsSignee &&
      createStore.filteredSignees.length === 1
    ) {
      await router.push({ path: destination.value, query: { ...route.query } });
    } else {
      emitter.$emit('open-invitations-dialog');
    }
  } catch (error) {
    startDocumentPending.value = false;
    console.error(error);
  }
}

function openCleanupConfirmationDialog() {
  confirmationDialog.value
    .open(confirmationTitle.value, confirmationText.value, null, {
      noCancel:
        isMissingSubscription.value &&
        !createStore.hasEmptyApprovers &&
        !createStore.hasEmptySignees &&
        !createStore.hasEmptyObservers,
      confirmActionText:
        isMissingSubscription.value &&
        !createStore.hasEmptyApprovers &&
        !createStore.hasEmptySignees &&
        !createStore.hasEmptyObservers
          ? i18n.t('buttons.close')
          : null
    })
    .then(async (result) => {
      if (result) {
        if (
          isMissingSubscription.value &&
          !createStore.hasEmptySignees &&
          !createStore.hasEmptyApprovers &&
          !createStore.hasEmptyObservers
        ) {
          return;
        }
        clearingEmptyInvitations.value = true;
        clearingEmptyObservers.value = true;
        await createStore.removeAllInviteeFieldsWithEmptyEmail();

        clearingEmptyInvitations.value = false;
        await createStore.removeAllObserversWithEmptyEmail();
        clearingEmptyObservers.value = false;

        if (!isMissingSubscription.value) {
          await storeStartedDocument(currentDocument.value!.documentId);
          currentDocument.value!.documentStatus = DocumentStatus.IN_PROGRESS;
          // emit open invitations dialog
          if (
            SignatureService.onlyMe(
              AuthService.user?.email,
              currentDocument.value
            )
          ) {
            await router.push({
              path: destination.value,
              query: { ...route.query }
            });
          } else {
            emitter.$emit('open-invitations-dialog');
          }
        } else {
          await proceed();
        }
      }
    });
}

async function proceed() {
  if (
    !createStore.hasEmptySignees &&
    !createStore.hasEmptyObservers &&
    !createStore.hasEmptyApprovers &&
    !createStore.hasEmptyApprovers
  ) {
    saveRecentlyUsedEmailsToPreferences();
    await startDocumentProcess();
  } else {
    openCleanupConfirmationDialog();
  }
}

const hasOnlySignees = computed(() =>
  currentDocument.value?.signeesOrdered
    .flat()
    .every((signee) => signee.signeeType === SigneeType.SIGN)
);

const userFields = computed(
  () =>
    currentDocument.value?.signeesOrdered
      .flat()
      .filter((signee) =>
        isGuest.value
          ? signee.email === userEmail.value
          : SignatureService.compareString(
              signee.email,
              AuthService.user?.email
            )
      ) || []
);

const shouldSign = computed(() => {
  if (isDocumentAuthRoute.value) return false;

  const hasPendingActions =
    userFields.value.length > 0
      ? userFields.value.every((signee) => {
          const emailMatch = isGuest.value
            ? signee.email === userEmail.value
            : SignatureService.compareString(
                signee.email,
                AuthService.user?.email
              );

          return (
            currentDocument.value &&
            emailMatch &&
            signee.signeeType === SigneeType.SIGN &&
            signee.signStatus === SignStatus.SIGNED &&
            (currentDocument.value?.documentStatus ===
              DocumentStatus.IN_PROGRESS ||
              currentDocument.value?.documentStatus === DocumentStatus.SIGNED ||
              currentDocument.value?.documentStatus ===
                DocumentStatus.WITHDRAWN)
          );
        })
      : false;

  return signStore.signInfo?.policy?.canSign || hasPendingActions;
});

const shouldApprove = computed(() => {
  const hasPendingActions =
    userFields.value.length > 0
      ? userFields.value.every((signee) => {
          const emailMatch = isGuest.value
            ? signee.email === userEmail.value
            : SignatureService.compareString(
                signee.email,
                AuthService.user?.email
              );

          return (
            currentDocument.value &&
            emailMatch &&
            signee.signeeType === SigneeType.APPROVE &&
            signee.signStatus === SignStatus.APPROVED &&
            (currentDocument.value?.documentStatus ===
              DocumentStatus.IN_PROGRESS ||
              currentDocument.value?.documentStatus === DocumentStatus.SIGNED ||
              currentDocument.value?.documentStatus ===
                DocumentStatus.WITHDRAWN)
          );
        })
      : false;
  return signStore.signInfo?.policy?.canApprove || hasPendingActions;
});

const isUserPartOfSignees = computed(() => {
  if (isGuest.value) {
    return SignatureService.shouldISign(
      userEmail.value,
      signStore.signInfo?.document?.signees || []
    );
  }
  return SignatureService.shouldISign(
    AuthService.user?.email,
    signStore.signInfo?.document?.signees || []
  );
});

const isSignedByUser = computed(() => {
  if (isGuest.value) {
    return SignatureService.isSignedByUser(
      userEmail.value,
      signStore.signInfo?.document?.signees || []
    );
  }
  return SignatureService.isSignedByUser(
    AuthService.user?.email,
    signStore.signInfo?.document?.signees || []
  );
});

function setAuthorityService(newAuthorityService) {
  authorityService.value = newAuthorityService;
}

function setUploadedFiles(newFiles) {
  uploadedFiles.value = newFiles;
}

function getAutographData() {
  if (autographData.value) {
    return base64ToFile(autographData.value, 'autograph.png');
  }
  if (signStore.signInfo?.defaultAutographUrl) {
    return base64ToFile(
      signStore.signInfo?.defaultAutographUrl,
      'autograph.png'
    );
  }
  return null;
}

function checkAsynchronousSign() {
  try {
    if (signStore.asynchronousSign?.signStatus === SignStatus.IN_PROGRESS) {
      pollInterval.value = setInterval(() => {
        signStore.fetchAuthSignStatus(signStore.signInfo?.signKey);
      }, 2500);
      emitter.$emit('show-sign-pending-dialog', {
        document: currentDocument.value,
        authorityService: authorityService.value
      });
    }
  } catch (error) {
    signStore.signPending = false;
    console.error('Error polling sign status: ', error);
  }
}

function updateSignatureData(eventData) {
  autographData.value = eventData;
}

function updateAgreementStatus(eventData) {
  agreement.value = eventData;
}

function updateChangeAutograph() {
  useDefaultAutograph.value = false;
}

function stopPolling() {
  clearInterval(pollInterval.value);
}

async function sign() {
  try {
    signStore.signPending = true;
    let canProceed = false;

    // We need to show attachments hint  if attachments are allowed and none was added
    if (
      signStore.signInfo?.policy.canModifySigneeAttachments &&
      uploadedFiles.value.length === 0
    ) {
      await signConfirmationDialog.value
        .open(
          i18n.t('titles.no_attachments'),
          i18n.t('texts.no_attachments_hint'),
          null,
          { confirmActionText: i18n.t('buttons.sign_anyway') }
        )
        .then((result) => {
          if (result) {
            canProceed = true;
          } else {
            signStore.signPending = false;
            canProceed = false;
          }
        });
    } else {
      canProceed = true;
    }

    if (canProceed) {
      let payload = null;

      // When user is not authenticated he needs to confirm accepting agreements
      if (!AuthService.isAuthenticated) {
        await signConfirmationDialog.value
          .open(
            i18n.t('titles.signature_confirmation'),
            getTranslatedTerms('guest_ses'),
            null,
            { confirmActionText: i18n.t('buttons.confirm'), width: 417 }
          )
          .then((result) => {
            canProceed = !!result;
            signStore.signPending = canProceed;
          });
      } else if (
        currentDocument.value.signatureMode !== SignatureMode.TIMESTAMP &&
        authorityService.value !== AuthorityService.DEEP_ID
      ) {
        // Mobile ID confirmation
        // const textKey = `terms.${currentDocument.value.jurisdiction}`;
        const textKey = getTranslatedTerms(currentDocument.value.jurisdiction);
        await signConfirmationDialog.value
          .open(i18n.t('titles.signature_confirmation'), textKey, null, {
            confirmActionText: i18n.t('buttons.confirm'),
            width: 417
          })
          .then((result) => {
            canProceed = result;
            signStore.signPending = canProceed;
          });
      }

      // Can finally sign the document
      if (canProceed) {
        // Sign SES as guest
        if (isGuest.value) {
          payload = {
            signKey: signStore.signInfo.signKey,
            sign: {
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null,
              comment: message.value
            },
            autograph: await getAutographData()
          };
          try {
            await signStore.signDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            signStore.signPending = false;
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
        } else {
          // Sign as authenticated user
          if (!isBasic.value && !isAdvancedEidas.value) {
            await preferencesStore.setPreference(
              'defaultAuthorityService',
              authorityService.value
            );
          }
          payload = {
            signKey: signStore.signInfo.signKey,
            sign: {
              comment: message.value,
              authorityService: authorityService.value,
              autographPosition: signStore.signInfo?.canModifyAutographPosition
                ? signStore.getPlacedSignaturePosition
                : null
            },
            autograph: await getAutographData()
          };

          try {
            await signStore.signAuthDocument(payload);
            signStore.signPending = false;
          } catch (error) {
            emitter.$emit('show-sign-error-dialog', {
              errorKey: error.response.data?.messageId,
              authorityService: authorityService.value,
              documentErrors: error.response.data?.details
            });
          }
          checkAsynchronousSign();
        }
      }
    }
  } catch (error) {
    signStore.signPending = false;
    console.error('Error signing the document', error);
  }
}

async function download() {
  try {
    await downloadDocumentPdf(
      currentDocument.value.signKey,
      currentDocument.value.documentName,
      currentDocument.value.documentStatus,
      currentDocument.value.documentId
    );
  } catch (error) {
    console.error('Error downloading document ', error);
  }
}
</script>
<style scoped lang="scss">
.drop-shadow {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
}
</style>
