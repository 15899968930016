<template>
  <div v-if="createStore.document">
    <div class="mt-6 drawer-section-title text-uppercase">
      {{ i18n.t('titles.who_needs_to_sign') }}
    </div>
    <InviteeSettings>
      <!-- SIGNEES OR APPROVERS -->
      <div
        v-if="createStore.hasInvitees"
        class="overflow-auto"
        :class="{
          'is-group': createStore.document.signeesOrdered.some(
            (group) => group.length > 1
          )
        }"
      >
        <v-form
          ref="signaturesForm"
          data-test-id="signing-form"
          @submit.prevent
        >
          <UnorderedInvitees
            v-if="!createStore.ordered"
            @on-blur="onBlur"
            @on-focus="onFocus"
            @on-update="handleSigneeDebounced"
            @on-update-key-down="handleSigneeDebounced"
            @remove-invitee="removeInviteeFromDocument"
          />
          <OrderedInvitees
            v-else
            @on-blur="onBlur"
            @on-focus="onFocus"
            @on-update="handleSigneeDebounced"
            @on-update-key-down="handleSigneeDebounced"
            @remove-invitee="removeInviteeFromDocument"
          />
        </v-form>
      </div>
      <!-- SIGNEES OR APPROVERS -->

      <div
        class="flex gap-4 mt-4 mb-6 justify-between"
        :class="{
          'mt-6': dragging,
          'flex-wrap': $vuetify.display.mobile || $vuetify.display.mdAndDown
        }"
      >
        <!-- ADD SIGNEE -->
        <v-btn
          :class="
            $vuetify.display.mobile || $vuetify.display.mdAndDown
              ? 'w-full'
              : 'w-1/2 flex-shrink-1'
          "
          color="primary"
          variant="outlined"
          data-test-id="add-signee"
          :disabled="createStore.isLoading"
          @click="addSigneeField"
        >
          {{ i18n.t('buttons.add_signatory') }}
        </v-btn>
        <!-- /ADD SIGNEE -->
        <!-- ADD APPROVER -->
        <v-btn
          v-if="expertSettings"
          class="!border-none"
          :class="
            $vuetify.display.mobile || $vuetify.display.mdAndDown
              ? 'w-full'
              : 'w-1/2 flex-shrink-1'
          "
          color="primary"
          variant="outlined"
          data-test-id="add-approver"
          :disabled="createStore.isLoading"
          @click="addApproverField"
        >
          {{ i18n.t('buttons.add_approver') }}
        </v-btn>
        <!-- /ADD APPROVER -->
      </div>
    </InviteeSettings>
  </div>
</template>
<script setup lang="ts">
import { useCreateStore } from '@/stores/create.ts';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import { useI18n } from 'vue-i18n';
import type { Signee } from '@/types/Signee.ts';
import { useEmitter } from '@/composables/useEmitter.ts';
import { cloneDeep, debounce } from '@/composables/useUtils.ts';
import type { AddSigneePayload } from '@/types/AddSigneePayload.ts';
import { useStorage } from '@vueuse/core';
import { usePreferencesStore } from '@/stores/deepadmin/preferences.ts';

const i18n = useI18n();
const router = useRouter();
const route = useRoute();
const createStore = useCreateStore();
const emitter = useEmitter();
const preferencesStore = usePreferencesStore();

const dragging = ref(false);
const currentFieldPreviousValue = ref();

const sessionExpertSettings = useStorage(
  'expertSettings',
  false,
  sessionStorage
);

const isPublicCreateRoute = computed(() => {
  return route.name === 'p-document-create';
});

const documentId = computed(() => {
  if (Array.isArray(router.currentRoute.value.params.document))
    return router.currentRoute.value.params.document[0];
  return router.currentRoute.value.params.document;
});

const expertSettings = computed(() => {
  if (isPublicCreateRoute.value) {
    return sessionExpertSettings.value;
  } else if (preferencesStore.preferences) {
    return preferencesStore.preferences?.expertSettings;
  } else {
    return false;
  }
});

async function addSigneeField() {
  createStore.isLoading = true;
  const payload = {
    documentId: documentId.value,
    signOrder: createStore.nextGroupIndex
  };
  const response = await createStore.addInvitee(payload);
  const invitee = response.data;
  createStore.addInviteeToLocalData(invitee);
  createStore.isLoading = false;
  await createStore.focusInputField(invitee.signeeId);
  if (createStore.placeSignaturesModel) {
    emitter.$emit(
      'place-signatures-automatically',
      createStore.filteredSignees
    );
  }
}

async function addApproverField() {
  createStore.isLoading = true;
  const payload: Partial<AddSigneePayload> = {
    documentId: documentId.value,
    signOrder: 0,
    signeeType: SigneeType.APPROVE
  };
  const response = await createStore.addInvitee(payload);
  await moveApproverToFirstPlace(response.data);
  createStore.isLoading = false;
  await createStore.focusInputField(response.data.signeeId);
}

async function moveApproverToFirstPlace(approver: Signee) {
  const tempGroups = cloneDeep(createStore.document?.signeesOrdered || []);
  // change the group order to have the approver as first item
  if (createStore.ordered) {
    tempGroups.splice(0, 0, [approver]);
    await createStore.updateDocumentSignOrder(documentId.value, tempGroups);
    createStore.document!.signeesOrdered =
      createStore.filterEmptyInBetweenGroups(tempGroups);
    createStore.sanitizeSigneeOrderedFields();
  } else {
    tempGroups.splice(0, 0, [approver]);
    await nextTick(async () => {
      await createStore.onOrderSwitchToggle(
        true,
        true,
        tempGroups,
        approver.signeeId
      );
    });
  }
}

async function removeInviteeFromDocument(invitee: Signee) {
  createStore.isLoading = true;
  await createStore.removeInvitee(invitee);
  createStore.deleteSignatureElement(invitee.signeeId);
  //add signee field if no fields are around
  if (!createStore.hasInvitees) {
    await addSigneeField();
  }
  createStore.isLoading = false;
}

function onBlur(signee: Signee) {
  if (!signee) return;
  const isValidEmail = createStore.validateEmail(signee.email);
  emitter.$emit('signee-blur', { ...signee, isValidEmail });
}

function onFocus(signee: Signee) {
  if (!signee) return;
  emitter.$emit('signee-focus', signee);
  currentFieldPreviousValue.value = { ...signee };
}

const handleSigneeDebounced = debounce(function (context) {
  const { event, signee } = context;
  handleSignee(event, signee);
}, 700);

async function handleSignee(_, signee: Signee) {
  if (!signee) return;

  try {
    createStore.isLoading = true;
    const isValidEmail = createStore.validateEmail(signee.email);
    if (!isValidEmail && signee.email) {
      createStore.isLoading = false;
      return;
    }

    if (signee && signee.signeeId && documentId.value) {
      await createStore.updateInvitee({
        documentId: documentId.value,
        signeeId: signee.signeeId,
        email: signee.email === '' ? null : signee.email,
        ...(signee.policy?.canModifyAutographPosition && {
          autographPosition: signee.autographPosition
        }),
        signOrder: signee.signOrder
      });

      currentFieldPreviousValue.value = { ...signee };
      if (createStore.placeSignaturesModel) {
        emitter.$emit('signee-blur', { ...signee, isValidEmail });
        if (isValidEmail) {
          createStore.changeEmailAddress(signee.signeeId, signee.email);
        }
      } else {
        emitter.$emit('signee-blur', signee);
      }
    }
    createStore.isLoading = false;
  } catch (error) {
    createStore.isLoading = false;
    currentFieldPreviousValue.value = { ...signee };
    console.error('Error handling signee (add / update)', error);
  }
}

watch(
  () => createStore.filteredSignees,
  (value) => {
    emitter.$emit('update-signee-fields', value);
  }
);

onMounted(async () => {
  if (!createStore.hasInvitees) {
    //this delay is needed for the public access case where the created document has no signees
    setTimeout(async () => {
      await addSigneeField();
    }, 600);
  }
});
</script>
