<template>
  <div class="flex flex-col">
    <div class="!p-0 text-black mb-4">
      {{ i18n.t('texts.invite_text_hint') }}
    </div>
    <v-form ref="inviteForm" @submit.prevent="sendInvitation">
      <v-text-field
        key="invitation-email-input"
        v-model.trim="email"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        type="email"
        :label="i18n.t('labels.email')"
        autofocus
        class="mt-2 bg-white email-input"
        density="compact"
        variant="outlined"
        :rules="emailRules"
        clearable
        clear-icon="fal fa-circle-xmark"
      />
    </v-form>

    <v-textarea
      v-model.trim="message"
      class="mt-2 invite-form-message mb-0"
      density="compact"
      hide-details
      no-resize
      variant="outlined"
      :label="i18n.t('labels.add_message')"
      :placeholder="i18n.t('placeholders.add_message')"
    />

    <portal to="document-dialog-actions">
      <VBtnSecondary variant="outlined" @click="closeDialog"
        >{{ i18n.t('buttons.cancel') }}
      </VBtnSecondary>
      <VBtnPrimary
        :disabled="!canSend"
        color="primary"
        data-test-id="send-invitation"
        @click="sendInvitation"
        >{{ i18n.t('buttons.send_invitation') }}
      </VBtnPrimary>
    </portal>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useValidationRules } from '@/composables/useValidationRules';
import { useSignStore } from '@/stores/sign/sign';
import { useDocumentContext } from '@/composables/useDocumentContext';
import { useMessageStore } from '@/stores/message/message';
import { useEmitter } from '@/composables/useEmitter';

const i18n = useI18n();
const emit = defineEmits(['closeDialog']);
const { emailRules } = useValidationRules();
const { currentDocument } = useDocumentContext();
const emitter = useEmitter();

// Store
const signStore = useSignStore();
const messageStore = useMessageStore();

// FORM
const inviteForm = ref();
const email = ref<string | null>(null);
const message = ref<string | null>(null);

const canSend = computed(() => {
  return (
    !!email.value &&
    /^[\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i.test(
      email.value
    )
  );
});

async function sendInvitation() {
  try {
    const isValid = inviteForm.value.validate();
    if (currentDocument.value && email.value && isValid) {
      await signStore.addNewSignee(currentDocument.value.documentId, {
        email: email.value,
        comment: message.value === '' ? null : message.value
      });
      messageStore.showMessage({
        key: 'snackbars.signee_added',
        color: 'success',
        icon: 'fa fa-circle-check'
      });

      closeDialog();
      emitter.$emit('update-sign-status');
    }
  } catch (error) {
    messageStore.showMessage({
      key: 'errors.error_occurred',
      color: 'error',
      icon: 'fa fa-circle-exclamation'
    });
  }
}

function closeDialog() {
  email.value = null;
  message.value = null;
  emit('closeDialog');
}
</script>

<style scoped lang="scss">
:deep(.v-text-field.v-text-field--enclosed) .v-text-field__details {
  padding-left: 0 !important;
}

.v-input :deep(.v-input__details) {
  padding-left: 0%;
  padding-top: 8px;
  min-height: 18px;
}

.v-input.v-text-field {
  margin-bottom: 0.75rem;
}

.email-input :deep(.slide-y-transition-leave-active) {
  transition: none !important;
}

.email-input :deep(.slide-y-transition-leave-to) {
  transition: none !important;
}

.email-input :deep(.slide-y-transition-enter-active) {
  transition: none !important;
}
.email-input :deep(.slide-y-transition-enter-to) {
  transition: none !important;
}
.invite-form-message :deep(.v-input__details) {
  visibility: none;
}
</style>
