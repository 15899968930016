<template>
  <DeepDialog
    v-model="dialog"
    fullscreen-on-mobile
    persistent
    width="600"
    :closable="false"
    :title="i18n.t('titles.check_your_inbox')"
  >
    <template #content>
      <div class="grid gap-2">
        <div class="flex flex-row items-center justify-start gap-4">
          <v-icon color="primary" size="24"> fas fa-envelope </v-icon>
          <p class="text-center">
            {{ i18n.t('texts.email_sent_to', { userEmail }) }}
          </p>
        </div>

        <v-alert
          v-if="emailResendState !== 'idle'"
          full-width
          style="width: 100%"
          variant="outlined"
          :type="emailResendState === 'error' ? 'error' : 'success'"
        >
          {{
            emailResendState === 'error'
              ? i18n.t('errors.error_occurred')
              : i18n.t('texts.invitation_sent')
          }}
        </v-alert>
      </div>
    </template>
    <template #actions>
      <VBtnPrimary
        :action="sendEmail"
        :loading="signStore.resendSidPending"
        :disabled="signStore.resendSidPending"
        :text="i18n.t('buttons.resend_email')"
      />
    </template>
  </DeepDialog>
</template>

<script setup lang="ts">
import { ref, onUnmounted } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useSignStore } from '@/stores/sign/sign';
import { useI18n } from 'vue-i18n';
import { useInvitationContext } from '@/composables/useInvitationContext';
import { useSignContext } from '@/composables/useSignContext';
import type { ComponentState } from '@/types/ui';
import { DeepDialog } from '@deepcloud/deep-ui-lib';

const i18n = useI18n();
const signStore = useSignStore();
const emitter = useEmitter();
const dialog = ref(false);
const emailResendState = ref<ComponentState>('idle');
const { invitationSignKey } = useInvitationContext();
const { userEmail } = useSignContext();

async function sendEmail() {
  try {
    emailResendState.value = 'idle';
    await signStore.resendSid(invitationSignKey.value);
    emailResendState.value = 'success';
  } catch (error) {
    emailResendState.value = 'error';
    console.error(error);
  }
}

emitter.$on('open-guest-send-email-dialog', open);

onUnmounted(() => {
  emitter.$off('open-guest-send-email-dialog', open);
});

function open() {
  dialog.value = true;
}
</script>

<style lang="scss" scoped>
@import '@/styles/core/colors';

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: $grey-lighten-2;
}
.card-container {
  display: grid;
  gap: 1rem;
  padding: 2rem;
}
.dialog-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.dialog-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 120px;
}
</style>
