<template>
  <Draggable
    v-if="createStore.document?.signeesOrdered"
    v-model="draggableGroups"
    item-key="index"
    animation="200"
    ghost-class="group-ghost"
    group="group"
    handle=".group-grip-icon"
    @change="onGroupDragUpdate"
    @end="dragging = false"
    @start="dragging = true"
  >
    <template #item="{ element: groupItem, index: groupIndex }">
      <InviteesDraggableGroup
        :key="groupIndex"
        class="sortable"
        :dragging="dragging"
        :disabled="disableDrag"
        :class="{
          'multi-item-group': groupItem.length > 1,
          'empty-group': groupItem.length === 0,
          highlight:
            !hiddenGroupIndexes.includes(groupIndex) &&
            currentDraggedGroupIndex !== null &&
            firstOrLast(groupIndex),
          drag: dragging || hiddenGroupIndexes.includes(groupIndex),
          'current-drag':
            groupIndex === currentDraggedGroupIndex && groupItem.length === 1
        }"
        :show-drag-notch="groupItem.length > 1"
      >
        <template #content>
          <Draggable
            :item-key="groupIndex.toString()"
            animation="200"
            ghost-class="item-ghost"
            group="items"
            :disabled="disableDrag"
            handle=".grip-icon"
            :list="groupItem"
            @change="onItemDragUpdate"
            @end="endItemDragging"
            @start="startItemDragging(groupIndex)"
          >
            <template #item="{ element, index }">
              <div class="flex flex-row align-center">
                <v-icon
                  size="large"
                  color="greyDarken1"
                  class="grip-icon transition-colors"
                  :class="{ dragging: draggingItem }"
                  data-test-id="drag-item"
                >
                  far fa-grip-dots-vertical
                </v-icon>
                <EmailSuggestion
                  :id="`email-${element.signeeId}`"
                  :key="`${element.signeeId}-${groupIndex}-${index}`"
                  v-model.trim="element.email"
                  :autocomplete="`${element.signeeId}`"
                  :name="`${element.signeeId}-${groupIndex}-${index}`"
                  class="my-3"
                  :label="getFieldLabel(element.signeeType)"
                  :data-test-id="`signee-email-${element.email}`"
                  :data-test-sign-order="`sign-order-${Math.ceil(groupIndex / 2)}`"
                  :class="{
                    initiator: isRequestorSignee(element)
                  }"
                  :readonly="isRequestorSignee(element)"
                  :rules="signatureRules"
                  :email-to-hide="initiatorSigneeEmail"
                  @blur="emit('on-blur', element)"
                  @focus="emit('on-focus', element)"
                  @update:model-value="
                    emit('on-update', { event: $event, signee: element })
                  "
                  @keydown.enter="
                    emit('on-update-key-down', {
                      event: $event,
                      signee: element
                    })
                  "
                >
                  <template #prepend-inner>
                    <v-icon
                      v-if="createStore.hasApprovers"
                      color="primary"
                      data-test-id="type"
                      >{{
                        element?.signeeType === SigneeType.SIGN
                          ? 'fa-light fa-pen-nib'
                          : 'fa-light fa-octagon-check'
                      }}</v-icon
                    >
                  </template>
                </EmailSuggestion>
                <UpdateOrderInput
                  :invitee="element"
                  :group-index="Math.ceil(groupIndex / 2)"
                  @disable-drag="onDisableDrag"
                />
                <v-btn
                  color="default"
                  :class="{
                    'opacity-25 cursor-not-allowed pointer-events-none':
                      isLoading
                  }"
                  class="text-gray-400 mx-1"
                  icon="fal fa-xmark"
                  size="medium"
                  :disabled="createStore.isLoading"
                  @click="emit('remove-invitee', element)"
                >
                  <v-icon>fal fa-xmark</v-icon>
                </v-btn>
              </div>
            </template>
          </Draggable>
        </template>
      </InviteesDraggableGroup>
    </template>
  </Draggable>
</template>
<script setup lang="ts">
import Draggable from 'vuedraggable';
import { SigneeType } from '@/types/enums/SigneeType.ts';
import { useCreateStore } from '@/stores/create.ts';
import { computed, ref } from 'vue';
import { useDocumentAuth } from '@/composables/useDocumentAuth.ts';
import { useValidationRules } from '@/composables/useValidationRules.ts';
import type { Signee } from '@/types/Signee.ts';
import { useI18n } from 'vue-i18n';

const dragging = ref(false);
const disableDrag = ref(false);
const documentAuth = useDocumentAuth();
const createStore = useCreateStore();
const { signatureRules } = useValidationRules();
const { t } = useI18n();
const hiddenGroupIndexes = ref<Array<number>>([]);
const currentDraggedGroupIndex = ref<number | null>(null);
const draggingItem = ref(false);

const emit = defineEmits([
  'remove-invitee',
  'on-update-invitee',
  'on-blur',
  'on-focus',
  'on-update',
  'on-update-key-down'
]);

defineProps({
  isLoading: {
    type: Boolean,
    default: false
  }
});

const draggableGroups = computed<Array<Array<Signee>>>(() => {
  const arrayWhitEmptySlots: Array<Array<Signee>> = [[]];
  createStore.document?.signeesOrdered.forEach((group: Array<Signee>) => {
    arrayWhitEmptySlots.push(group as Array<Signee>);
    arrayWhitEmptySlots.push([]);
  });
  return arrayWhitEmptySlots;
});

const initiatorSigneeEmail = computed(() => {
  return createStore.document?.initiatorDisplayEmail || '';
});

const isDocumentAuthAuthenticated = computed(() => {
  return documentAuth.isAuthenticated.value;
});

function isRequestorSignee(signee: Signee) {
  return (
    signee.signeeId === createStore.initiatorAsSignee?.signeeId &&
    !isDocumentAuthAuthenticated.value
  );
}

function firstOrLast(index: number) {
  return index === 0 || index === (draggableGroups.value.length - 1 || 0);
}

function onGroupDragUpdate(event) {
  const { moved } = event;

  // Calculate old and new index
  const oldIndex = moved.oldIndex / 2 - 0.5;
  const difference = (moved.newIndex - moved.oldIndex) / 2;
  const newIndex = oldIndex + difference;

  // Remove the moved group from the original position
  const tempData = createStore.document?.signeesOrdered || [[]];
  const movedGroup = tempData.splice(oldIndex, 1);

  // Insert the moved group at the new position
  tempData.splice(newIndex, 0, ...movedGroup);

  // Update the sign order for each group
  const updatedGroups = tempData.map((group, index) =>
    group.map((s) => ({ ...s, signOrder: index }))
  );

  // Update the document's sign order
  createStore.updateDocumentSignOrder(
    createStore.document!.documentId,
    updatedGroups
  );
  createStore.document!.signeesOrdered = updatedGroups;
}

function startItemDragging(index: number) {
  draggingItem.value = true;
  currentDraggedGroupIndex.value = index;
  if (draggableGroups.value[index].length === 1) {
    hiddenGroupIndexes.value = [index - 1, index + 1];
  }
}

function endItemDragging() {
  draggingItem.value = false;
  currentDraggedGroupIndex.value = null;
  hiddenGroupIndexes.value = [];
}

async function onItemDragUpdate() {
  const newOrderedGroups = draggableGroups.value.filter(
    (group) => group.length > 0
  );
  await createStore.updateDocumentSignOrder(
    createStore.document!.documentId,
    newOrderedGroups
  );
  createStore.updateSignOrderInLocalData(newOrderedGroups);
}

function onDisableDrag(disableDragValue: boolean) {
  disableDrag.value = disableDragValue;
}

function getFieldLabel(signeeType: SigneeType) {
  return signeeType === SigneeType.SIGN
    ? t('labels.signee_email')
    : t('labels.approver_email');
}
</script>
<style scoped lang="scss">
:deep(.v-field__input) {
  @apply pr-[44px];
}
</style>
